import { Link } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { OrganizationAggregate } from "../../../dtos/organizationAggregate";
import { useAuth } from "../../../features/auth/context";
import { StandardTable } from "../../shared/table";

import { ActivationButton } from "./ActivationButton";
import { DeleteOrgButton } from "./DeleteOrgButton";
import { EditOrgButton } from "./EditOrgButton";

interface Props {
    orgs: OrganizationAggregate[];
    orgsAreLoading: boolean;
    dealerId: number | undefined;
    onDeleteOrg: (id: number) => void;
    onLoadOrgs: () => void;
}

export function OrgsTable({
    orgs,
    orgsAreLoading,
    dealerId,
    onDeleteOrg,
    onLoadOrgs,
}: Props) {
    const { isGlobalAdmin, isDealerAdmin } = useAuth();

    const showActivationButton = isGlobalAdmin || isDealerAdmin;

    return (
        <StandardTable>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Dealer</th>
                    <th># of Sites</th>
                    <th># of Customers</th>
                    <th># of Employees</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    {showActivationButton && (
                        <th></th>
                    )}
                </tr>
            </thead>
            <tbody>
                {orgs.map((o) => (
                    <tr key={o.id}>
                        <td>{o.id}</td>
                        <td>{o.name}</td>
                        <td>{o.dealerName}</td>
                        <td>{o.numberOfSites}</td>
                        <td>{o.numberOfCustomers}</td>
                        <td>{o.numberOfEmployees}</td>
                        <td>
                            <Link
                                component={RouterLink}
                                to={`/dealers/${dealerId}/organizations/${o.id}/sites`}
                            >
                                Sites
                            </Link>
                        </td>
                        <td>
                            <EditOrgButton
                                org={o}
                                onLoadOrgs={onLoadOrgs}
                                orgsAreLoading={orgsAreLoading}
                            />
                        </td>
                        <td>
                            <DeleteOrgButton
                                org={o}
                                onDeleteOrg={onDeleteOrg}
                            />
                        </td>
                        {showActivationButton && (
                            <td>
                                <ActivationButton
                                    org={o}
                                    onOrgActivationChange={onLoadOrgs}
                                    orgsAreLoading={orgsAreLoading}
                                />
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </StandardTable>
    )
}