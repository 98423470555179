import { useMsal } from "@azure/msal-react";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import { SSO_AUTHORITIES, SSO_SCOPE } from "../../../constants";
import { AuthState } from "../enums";

import { IMainAuthContext, MainAuthContextProvider, useMainAuth } from "./mainAuthContext";
import { MsalContextProvider } from "./msalContext";

type IAuthContext = {
    login: () => void;
    logout: () => void;
    getToken: (attempt: number) => void;
    changePassword: () => void;
    state: AuthState;
} & IMainAuthContext;

const AuthContext = createContext<IAuthContext | null>(null);

function AuthContextProvider({ children }: React.PropsWithChildren<unknown>) {
    const { instance } = useMsal();
    const mainAuth = useMainAuth();

    const [state, setState] = useState(AuthState.Idle);

    const login = useCallback(async () => {
        setState(AuthState.Login);

        try {
            await instance.loginRedirect({
                authority: SSO_AUTHORITIES.paznicCustom,
                scopes: [SSO_SCOPE],
                prompt: "login",
                extraQueryParameters: {
                    origin: window.location.origin,
                },
            });
        } catch (error) {
            console.error(error);
            setState(AuthState.Error);
        }
    }, [instance]);


    const changePassword = useCallback(async () => {
        try {
            await instance.loginRedirect({
                authority: SSO_AUTHORITIES.changePassword,
                scopes: [SSO_SCOPE],
                prompt: "login",
                extraQueryParameters: {
                    hint: mainAuth.email || "",
                    origin: window.location.origin,
                },
            });
        } catch (error) {
            console.error("Password change failed:", error);
        }
    }, [instance, mainAuth.email]);

    const getToken = useCallback(async (attempt: number) => {
        setState(AuthState.GetToken);
        const accounts = instance.getAllAccounts();

        if (accounts.length === 0) {
            if (attempt > 2) setState(AuthState.Idle);
            return;
        }

        try {
            await instance.initialize();
            const response = await instance.acquireTokenSilent({
                authority: SSO_AUTHORITIES.paznicCustom,
                scopes: [SSO_SCOPE],
                account: accounts[0],
            });
            localStorage.setItem("token", response.accessToken);
            mainAuth.mainLogin();
        } catch (error) {
            console.error(error);
            setState(AuthState.Error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, mainAuth.mainLogin]);

    const logout = useCallback(async () => {
        try {
            mainAuth.mainLogout();
            await instance.logout({
                authority: SSO_AUTHORITIES.paznicCustom,
            });
        } catch (error) {
            console.error(error);
            setState(AuthState.Error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, mainAuth.mainLogout]);

    useEffect(() => {
        setState(prev => mainAuth.loginInProgress
            ? AuthState.MainLogin
            : prev === AuthState.MainLogin
                ? AuthState.Idle
                : prev);
    }, [mainAuth.loginInProgress]);

    useEffect(() => {
        setState(prev => mainAuth.loginErrors.length > 0
            ? AuthState.Error
            : prev === AuthState.Error
                ? AuthState.Idle
                : prev);
    }, [mainAuth.loginErrors]);

    useEffect(() => {
        if (state !== AuthState.Login) return;

        const timeout = setTimeout(() => {
            setState(prev => prev === AuthState.Login ? AuthState.Idle : prev);
        }, 3000);

        return () => clearTimeout(timeout);
    }, [state]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (mainAuth.isAuthenticated) {
            interval = setInterval(() => {
                const expDate = localStorage.getItem('refreshTokenExpDate');
                if (expDate == null || new Date(expDate) < new Date()) {
                    console.debug('token expired: ' + expDate);
                    logout();
                    clearInterval(interval);
                }
            }, 2000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [logout, mainAuth.isAuthenticated]);

    return (
        <AuthContext.Provider value={{
            ...mainAuth,
            login,
            logout,
            changePassword,
            getToken,
            state,
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export function FullAuthContextProvider({ children }: React.PropsWithChildren<unknown>) {
    return (
        <MsalContextProvider>
            <MainAuthContextProvider>
                <AuthContextProvider>
                    {children}
                </AuthContextProvider>
            </MainAuthContextProvider>
        </MsalContextProvider>
    );
}


export function useAuth() {
    const value = useContext(AuthContext);

    if (!value) {
        throw new Error("AuthContext consumed outside of AuthContextProvider scope");
    }

    return value;
}
