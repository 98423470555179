import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { PageHeader } from '../../PageHeader';
import { Box, BoxForDisplay } from '../../../dtos/box';
import { AjaxResult } from '../../../enums/ajaxResult';
import { StandardTable } from '../../shared/table';
import { Link, Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, styled } from '@material-ui/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { OrgSelect } from '../../shared/OrgSelect';
import { useApi } from '../../useApi';
import { Pagination } from '../../shared/pagination';
import { ItemsPerPageInput } from '../../shared/itemsPerPageInput';
import { useOrgSiteContext } from '../../../hooks/useOrgSiteContext';
import { ValueType } from 'react-select/src/types';
import { DialogSelect } from '../../shared/dialogSelect';
import linq from 'linq';
import { SearchBar } from '../events/searchBar';
import { UserLevel } from '../../../enums/userLevel';
import { HelpVideo } from '../../../enums/helpVideo';
import { ExportReportButton } from '../../shared/ExportReportButton';
import { ButtonsContainer } from '../../shared/ButtonsContainer';

import { useAuth } from "../../../features/auth/context";

export const BoxesPage = () => {
    const classes = useStyles();
    const { boxApi } = useApi();
    const { roles, isGlobalAdmin, isDealerAdmin, isOrgAdmin } = useAuth();
    const deassocciateSiteFromContext =
        roles.indexOf(UserLevel.SiteAdmin) !== -1 ||
        roles.indexOf(UserLevel.SiteEmployee) !== -1 ||
        roles.indexOf(UserLevel.OrgAdmin) !== -1;

    const [boxes, setBoxes] = useState<Box[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(500);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [availableOnly, setAvailableOnly] = useState<boolean>(false);
    const [location, setLocation] = useState<string>('');
    const [locations, setLocations] = useState<string[]>([]);
    const [size, setSize] = useState<string>('');
    const [sizes, setSizes] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const search = useCallback((text: string) => setSearchText(text), []);
    const options = useMemo(
        () => ({
            itemsPerPage,
            page: page + 1,
            availableOnly: availableOnly,
            location: location,
            size: size,
            searchText: searchText,
        }),
        [page, itemsPerPage, availableOnly, location, size, searchText]
    );

    const { dealerId, orgId, siteId, siteChangeCallback } = useOrgSiteContext('boxes');

    useEffect(() => {
        if (boxes !== [] && orgId && siteId) {
            boxApi.getBoxFilterOptions(availableOnly, orgId, siteId).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    setLocations(r.data.locations);
                    setSizes(r.data.sizes);
                }
            });
        }
    }, [boxApi, boxes, availableOnly, orgId, siteId]);

    const locationOptions = useMemo(
        () =>
            linq
                .from(locations)
                .select((l) => ({ value: locations.indexOf(l), label: l }))
                .toArray(),
        [locations]
    );
    const selectedLocationOption =
        linq.from(locationOptions).firstOrDefault((d) => d.label === location) || null;
    const sizeOptions = useMemo(
        () =>
            linq
                .from(sizes)
                .select((l) => ({ value: sizes.indexOf(l), label: l }))
                .toArray(),
        [sizes]
    );
    const selectedSizeOption =
        linq.from(sizeOptions).firstOrDefault((d) => d.label === size) || null;

    useEffect(() => {
        const apiMethod =
            siteId && orgId ? () => boxApi.getBoxesForSite(orgId, siteId, options) : undefined;

        if (apiMethod === undefined) {
            return;
        }

        setIsLoading(true);
        apiMethod().then((r) => {
            setIsLoading(false);
            if (r.result === AjaxResult.Success && r.data) {
                setBoxes(r.data.boxes);
                setNumberOfPages(r.data.numberOfPages);
            }
        });
    }, [orgId, siteId, boxApi, options]);

    const boxesForDisplay = useMemo(
        () =>
            boxes
                .sort((a, b) => (a.number < b.number ? -1 : 1))
                .map(
                    (b) =>
                    ({
                        ...b,
                        status: !b.owners ? 'Open' : '',
                        leaseEndDate:
                            b.leaseEndDate && new Date(b.leaseEndDate).toLocaleDateString(),
                    } as BoxForDisplay)
                ),
        [boxes]
    );

    const reportRequest = useMemo(() => {
        if (!orgId || !siteId) return;
        return () => boxApi.getBoxesReportForSite(orgId, siteId, options);
    }, [orgId, siteId, options, boxApi]);

    const resetFilters = useCallback(() => {
        setSize("");
        setLocation("");
        setAvailableOnly(false);
    }, []);

    return (
        <>
            <PageHeader helpVideoNum={HelpVideo.Boxes}>Boxes</PageHeader>

            {isGlobalAdmin || isDealerAdmin || isOrgAdmin ? (
                <OrgSelect
                    selectedOrgId={orgId}
                    selectedSiteId={siteId}
                    selectedDealerId={dealerId}
                    bypassSiteContext={deassocciateSiteFromContext}
                    onChange={(val: {
                        dealerId?: number | null;
                        orgId?: number | null;
                        siteId?: number | null;
                    }) => siteChangeCallback(val.dealerId, val.orgId, val.siteId)}
                />
            ) : null}

            <SearchBar
                defaultSearchText={searchText}
                search={search}
                labelText="Search Boxes..."
                onReset={resetFilters}
            />

            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            value={availableOnly}
                            color={'primary'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setAvailableOnly(event.target.checked)
                            }
                            checked={availableOnly}
                        />
                    }
                    label="Show Available Boxes Only"
                />
            </div>
            <div className={classes.container}>
                <DialogSelect
                    options={locationOptions}
                    value={selectedLocationOption}
                    onChange={(value: ValueType<{ value: number; label: string }>) => {
                        if (value === null) {
                            setLocation('');
                        } else {
                            const val = value as { value: number; label: string };
                            setLocation(val.label);
                        }
                    }}
                    isClearable
                    placeholder={'Select location...'}
                    className={classes.select}
                />
                <DialogSelect
                    options={sizeOptions}
                    value={selectedSizeOption}
                    onChange={(value: ValueType<{ value: number; label: string }>) => {
                        if (value === null) {
                            setSize('');
                        } else {
                            const val = value as { value: number; label: string };
                            setSize(val.label);
                        }
                    }}
                    isClearable
                    placeholder={'Select size...'}
                    className={classes.select}
                />
            </div>

            <ButtonsContainer>
                <ExportReportButton
                    reportRequest={reportRequest}
                    reportTarget="Boxes"
                />
            </ButtonsContainer>

            <PaginationContainer>
                <Pagination {...{ setPage, page, pageCount: numberOfPages }} />
                <ItemsPerPageInput value={itemsPerPage} onChange={setItemsPerPage} />
            </PaginationContainer>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <StandardTable>
                    <thead>
                        <tr>
                            <th>Box #</th>
                            <th>Availability</th>
                            <th>Location</th>
                            <th>Size</th>
                            <th>Owners</th>
                            <th>Lease End Date</th>
                            <th>Notes</th>
                            <th>Organization</th>
                            <th>Site</th>
                        </tr>
                    </thead>
                    <tbody>
                        {boxesForDisplay.map((b) => (
                            <tr key={b.id}>
                                <td>
                                    <Link
                                        component={RouterLink}
                                        to={`${dealerId ? `/dealers/${dealerId}` : ''}${orgId ? `/organizations/${orgId}` : ''
                                            }${siteId ? `/sites/${siteId}` : ''}/boxDetail/${b.id}`}
                                    >
                                        {b.number}
                                    </Link>
                                </td>
                                <td>
                                    <AvailabilityIndicator {...b} />
                                </td>
                                <td>{b.location}</td>
                                <td>{b.size}</td>
                                <td>{b.owners}</td>
                                <td>{b.leaseEndDate}</td>
                                <td>{b.notes}</td>
                                <td>{b.organization}</td>
                                <td>{b.site}</td>
                            </tr>
                        ))}
                    </tbody>
                </StandardTable>
            )}
        </>
    );
};

const PaginationContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        marginTop: '5px',
    },
    select: {
        minWidth: '200px',
    },
}));

const AvailabilityIndicator = ({ isExpired, owners }: { owners?: string; isExpired?: boolean }) => {
    let status: 'available' | 'expired' | 'taken';
    if (owners) {
        if (isExpired) {
            status = 'expired';
        } else {
            status = 'taken';
        }
    } else {
        status = 'available';
    }
    switch (status) {
        case 'available':
            return <AvailableIndicatorStyle>Available</AvailableIndicatorStyle>;
        case 'expired':
            return <ExpiredIndicatorStyle>Expired</ExpiredIndicatorStyle>;
        case 'taken':
        default:
            return null;
    }
};

const ExpiredIndicatorStyle = styled('span')(({ theme }: { theme: ThemeOptions }) => ({
    backgroundColor: 'red',
    color: theme.backgroundThemeColor1,
    display: 'inline-block',
    padding: '5px',
    borderRadius: '2.5px',
}));

const AvailableIndicatorStyle = styled('span')(({ theme }: { theme: ThemeOptions }) => ({
    backgroundColor: 'black',
    color: theme.backgroundThemeColor1,
    display: 'inline-block',
    padding: '5px',
    borderRadius: '2.5px',
}));
