import React, { useMemo } from "react";

import { HelpVideo } from "../../../enums/helpVideo";
import { UserLevel } from "../../../enums/userLevel";
import { useAuth } from "../../../features/auth/context";
import { useFullSitesLoader } from "../../../hooks/useFullSitesLoader";
import { useOrgSiteContext } from "../../../hooks/useOrgSiteContext";
import { PageHeader } from "../../PageHeader";
import { OrgSelect } from "../../shared/OrgSelect";

import { ServerStatus } from "./ServerStatus";
import { SitesTable } from "./SitesTable";
import { ReportsLink } from "./ReportsLink";

export function HomePage() {
    const { roles, isGlobalAdmin, isDealerAdmin } = useAuth();

    const deassocciateSiteFromContext =
        roles.indexOf(UserLevel.SiteAdmin) !== -1 ||
        roles.indexOf(UserLevel.SiteEmployee) !== -1 ||
        roles.indexOf(UserLevel.OrgAdmin) !== -1;

    const assignedOrgAndSite = useAuth();
    const {
        dealerId: contextDealerId,
        orgId: contextOrgId,
        siteId: contextSiteId,
        siteChangeCallback,
    } = useOrgSiteContext("home");

    const dealerId = useMemo(
        () => contextDealerId || assignedOrgAndSite.dealerId,
        [assignedOrgAndSite.dealerId, contextDealerId]
    );
    const orgId = useMemo(
        () => contextOrgId || assignedOrgAndSite.orgId,
        [assignedOrgAndSite.orgId, contextOrgId]
    );
    const siteId = useMemo(
        () => contextSiteId || assignedOrgAndSite.siteId,
        [contextSiteId, assignedOrgAndSite.siteId]
    );

    const { sites, loading } = useFullSitesLoader({ dealerId, siteId, orgId })

    return (
        <>
            <PageHeader
                helpVideoNum={HelpVideo.Login}
                isLoading={loading}
            >
                Home Page
            </PageHeader>

            <ServerStatus />

            <OrgSelect
                dealerSelectIsEnabled={isGlobalAdmin}
                orgSelectIsEnabled={isGlobalAdmin || isDealerAdmin}
                selectedDealerId={dealerId || null}
                selectedOrgId={orgId || null}
                selectedSiteId={siteId || null}
                onChange={(val: {
                    dealerId?: number | null;
                    orgId?: number | null;
                    siteId?: number | null;
                }) => siteChangeCallback(val.dealerId, val.orgId, val.siteId)}
                bypassSiteContext={deassocciateSiteFromContext}
            />

            <SitesTable
                sites={sites}
                dealerId={dealerId}
            />

            <ReportsLink
                dealerId={dealerId}
                orgId={orgId}
                siteId={siteId}
            />
        </>
    );
};
