import React from "react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import {
    SSO_CLIENT_ID,
    SSO_DOMAIN,
    SSO_REDIRECT_URL,
} from "../../../constants";


const msalConfig: Configuration = {
    auth: {
        clientId: SSO_CLIENT_ID,
        redirectUri: SSO_REDIRECT_URL,
        knownAuthorities: [SSO_DOMAIN],
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    }
};

const msalInstance = new PublicClientApplication(msalConfig);

export function MsalContextProvider({ children }: React.PropsWithChildren<unknown>) {
    return (
        <MsalProvider instance={msalInstance}>
            {children}
        </MsalProvider>
    );
}
