import { useState, useMemo, useEffect, useCallback } from 'react';
import moment, { Moment } from 'moment';
import { Event } from '../../../dtos/event';
import { AjaxResult } from '../../../enums/ajaxResult';
import { useApi } from '../../useApi';
import { useSnackbar } from 'notistack';
import useRouter from 'use-react-router';
import { useOrgSiteContext } from '../../../hooks/useOrgSiteContext';
import { EventType, ManagementEventDisplays } from '../../../enums/eventType';
import { AcsEventNameDisplay } from '../../../enums/acsEventName';
import { ManagementEventNameDisplay } from '../../../enums/managementEventName';
import { SiteEventNameDisplay } from '../../../enums/SiteEventName';

import { useAuth } from "../../../features/auth/context";

interface RouteState {
    to?: string;
    from?: string;
    filterByLocalTime: boolean;
    eventType?: EventType | null;
    eventName?: number | null;
    searchText?: string;
}

export const useEventLogPageState = () => {
    const { roles } = useAuth();
    const { history } = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const { eventApi } = useApi();
    // const { orgId, siteId } = match.params;
    const routeState = history.location.state as RouteState;

    const [to, setTo] = useState<Moment | null>(
        routeState && routeState.to ? moment(routeState.to) : moment().add(1, 'day')
    );
    const [from, setFrom] = useState<Moment | null>(
        routeState && routeState.from ? moment(routeState.from) : moment().subtract(30, 'days')
    );
    const [filterByLocalTime, setFilterByLocalTime] = useState<boolean | null>(
        routeState ? routeState.filterByLocalTime : true
    );
    const [eventType, selectEventType] = useState<EventType | null>(
        routeState && routeState.eventType ? routeState.eventType : null
    );
    const [eventName, selectEventName] = useState<number | null>(
        routeState && routeState.eventName ? routeState.eventName : null
    );
    const [itemsPerPage, setItemsPerPage] = useState(500);
    const [searchText, setSearchText] = useState(
        routeState && routeState.searchText ? routeState.searchText : ''
    );
    const search = useCallback((text: string) => setSearchText(text), []);
    const [employeeToSearch, setEmployeeToSearch] = useState<string | undefined>();
    const [customerToSearch, setCustomerToSearch] = useState<string | undefined>();
    const [selectedPage, setPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [events, setEvents] = useState<Event[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { dealerId, siteId, orgId, siteChangeCallback } = useOrgSiteContext('reports', {
        to: to && to.toISOString(),
        from: from && from.toISOString(),
        filterByLocalTime: filterByLocalTime,
        eventType: eventType,
        eventName: eventName,
        searchText,
    } as RouteState);

    // if selectedPage is greater than the number of pages, use the max page instead
    // BTW, the pagination component we're using has zero-based indexing under the hood, thus the " - 1" or " + 1" going on
    const page = useMemo(() => (selectedPage > numberOfPages ? numberOfPages - 1 : selectedPage), [
        numberOfPages,
        selectedPage,
    ]);
    const requestOptions = useMemo(
        () => ({
            from: from && from.toISOString(),
            to: to && to.toISOString(),
            filterByLocalTime: filterByLocalTime,
            eventType: eventType,
            eventName: eventName,
            searchText: searchText,
            page: page + 1,
            itemsPerPage,
            employeeToSearch: employeeToSearch || null,
            customerToSearch: customerToSearch || null,
        }),
        [
            to,
            from,
            filterByLocalTime,
            eventType,
            eventName,
            searchText,
            page,
            itemsPerPage,
            employeeToSearch,
            customerToSearch,
        ]
    );

    useEffect(() => {
        const abortController = new AbortController();
        let apiMethod = () => eventApi.getEvents(requestOptions, abortController);
        if (dealerId && !orgId && !siteId) {
            apiMethod = () =>
                eventApi.getEventsForDealer(dealerId, requestOptions, abortController);
        }
        if (orgId && !siteId) {
            apiMethod = () => eventApi.getEventsForOrg(orgId, requestOptions, abortController);
        }
        if (siteId && orgId) {
            apiMethod = () =>
                eventApi.getEventsForSite(orgId, siteId, requestOptions, abortController);
        }
        setIsLoading(true);
        apiMethod().then((r) => {
            if (r.result === AjaxResult.Success && r.data) {
                setEvents(r.data.events);
                setNumberOfPages(r.data.numberOfPages);
                setIsLoading(false);
            } else if (r.result !== AjaxResult.Cancelled) {
                enqueueSnackbar('Couldnt get events!');
                setIsLoading(false);
            }
        });
        return () => abortController.abort();
    }, [requestOptions, dealerId, orgId, siteId, eventApi, enqueueSnackbar]);
    const eventTypeOptions = useMemo(() => Array.from(ManagementEventDisplays), []);
    const eventNameOptions = useMemo(() => {
        switch (eventType) {
            case EventType.AcsEvent:
                return Array.from(AcsEventNameDisplay);
            case EventType.ManagementEvent:
                return Array.from(ManagementEventNameDisplay);
            case EventType.SiteEvent:
                return Array.from(SiteEventNameDisplay);
            default:
                return [];
        }
    }, [eventType]);
    return {
        roles,
        dealerId,
        orgId,
        siteId,
        searchText,
        setItemsPerPage,
        itemsPerPage,
        to,
        from,
        eventType,
        eventTypeOptions,
        eventName,
        eventNameOptions,
        page,
        numberOfPages,
        isLoading,
        events,
        setPage,
        selectEventName,
        selectEventType,
        setFrom,
        setTo,
        siteChangeCallback,
        search,
        employeeToSearch,
        setEmployeeToSearch,
        customerToSearch,
        setCustomerToSearch,
        requestOptions,
        filterByLocalTime,
        setFilterByLocalTime,
    };
};
