import { makeStyles } from "@material-ui/core";
import React from "react";

import { useAuth } from "../features/auth/context";

import { BannerAboutDeactivation } from "./BannerAboutDeactivation";
import { SiteOfflineBanner } from "./SiteOfflineBanner";
import { Search } from "./Search";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.backgroundThemeColor2,
        padding: "5px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export function TopBar() {
    const classes = useStyles();
    const { isGlobalAdmin, isDealerAdmin } = useAuth();;
    const showSearch = isDealerAdmin || isGlobalAdmin;

    return (
        <div className={classes.container}>
            <BannerAboutDeactivation />
            <SiteOfflineBanner />
            {showSearch && <Search />}
        </div>
    );
}
