import { useCallback } from "react";

import { useAuth } from "../../../features/auth/context";
import { useFullSitesLoader } from "../../../hooks/useFullSitesLoader";

interface Props {
    dealerId: number | undefined;
    orgId: number | undefined;
    siteId: number | undefined;
}

export function useSiteColumn({ dealerId, orgId, siteId }: Props): {
    sitesAreLoading: boolean,
    getSiteName: (id: number | undefined) => string,
    showSiteColumn: boolean;
} {
    const { isGlobalAdmin, isDealerAdmin, isOrgAdmin } = useAuth();

    const columnShown =
        (isGlobalAdmin || isDealerAdmin || isOrgAdmin)
        && !siteId
        && Boolean(dealerId || orgId);

    const { sites, loading } = useFullSitesLoader({
        dealerId,
        orgId,
        siteId,
        disabled: !columnShown,
    });

    const getSiteName = useCallback((siteId: number | undefined) => {
        const site = sites.find(it => it.id === siteId);
        return site ? site.name : "";
    }, [sites]);

    return {
        sitesAreLoading: loading,
        getSiteName,
        showSiteColumn: columnShown,
    }
}
