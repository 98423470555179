import React, { useState, useEffect, useContext } from 'react';
import { PageHeader } from '../../PageHeader';
import { Button, styled } from '@material-ui/core';
import { AjaxResult } from '../../../enums/ajaxResult';
import { useSnackbar } from 'notistack';
import SignalRContext from '../../../signalr/context';
import { OrgSelect } from '../../shared/OrgSelect';
import { useApi } from '../../useApi';
import { Customer } from '../../../dtos/customer';
import { useOrgSiteContext } from '../../../hooks/useOrgSiteContext';
import { UserLevel } from '../../../enums/userLevel';
import { HelpVideo } from '../../../enums/helpVideo';
import { ManualOverrideRequestData } from '../../../dtos/manualOverride';

import { CustomerSelect } from './CustomerSelect';
import { ManualOverrideDialog } from './ManualOverrideDialog';

import { useAuth } from "../../../features/auth/context";

export const ManualOverridePage = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { customerApi, siteApi } = useApi();

    const { roles, isGlobalAdmin, isDealerAdmin, isOrgAdmin } = useAuth();
    const { dealerId, orgId, siteChangeCallback, siteId } = useOrgSiteContext('manual-override');
    const deassocciateSiteFromContext = roles.indexOf(UserLevel.OrgAdmin) !== -1;

    const [customers, setCustomers] = useState<Customer[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const { serviceStatus } = useContext(SignalRContext);
    const [requestData, setRequestData] = useState<ManualOverrideRequestData>();

    useEffect(() => {
        setCustomers([]);
        if (orgId && siteId) {
            customerApi.getCustomersForSiteBox(orgId, siteId).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    setCustomers(r.data);
                }
            });
        } else {
            setCustomers([]);
        }
        return () => {
            closeSnackbar();
        };
    }, [orgId, siteId, customerApi, closeSnackbar]);

    const endSession = (siteId: number) => {
        siteApi.endSession(siteId).then((r) => {
            if (r.result === AjaxResult.Success) {
                enqueueSnackbar('Session ended', { variant: 'success', persist: false });
            } else {
                alert('ERROR: failed end session');
            }
        });
    };

    const manualOverrideAllowed =
        siteId !== undefined && selectedCustomer !== null
            ? selectedCustomer.activeLeaseSiteIds.findIndex((s) => s === siteId) !== -1
            : true;

    return (
        <>
            <PageHeader helpVideoNum={HelpVideo.ManualOverride}>
                Manual Override
            </PageHeader>

            {(isGlobalAdmin || isDealerAdmin || isOrgAdmin) && (
                <OrgSelect
                    selectedDealerId={dealerId}
                    selectedOrgId={orgId}
                    selectedSiteId={siteId}
                    bypassSiteContext={deassocciateSiteFromContext}
                    onChange={(val: {
                        dealerId?: number | null;
                        orgId?: number | null;
                        siteId?: number | null;
                    }) => {
                        siteChangeCallback(val.dealerId, val.orgId, val.siteId);
                    }}
                />
            )}

            <h2>Start Session</h2>

            <CustomerSelect
                disabled={!siteId}
                customers={customers}
                onCustomerSelect={setSelectedCustomer}
            />

            <div>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={
                        !orgId ||
                        !siteId ||
                        !selectedCustomer ||
                        !serviceStatus.get(siteId) ||
                        !manualOverrideAllowed
                    }
                    onClick={() =>
                        siteId &&
                        selectedCustomer &&
                        setRequestData({
                            siteId: siteId,
                            customerId: selectedCustomer.id
                        })
                    }
                >
                    Start Session
                </Button>

                {!manualOverrideAllowed && (
                    <Message>Customer must have an active lease to start a session at a site.</Message>
                )}

                {siteId && !serviceStatus.get(siteId) && (
                    <Message>The site is currently offline</Message>
                )}
            </div>

            <h2>End Session</h2>

            <div>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={!orgId || !siteId}
                    onClick={() => siteId && endSession(siteId)}
                >
                    End Session
                </Button>
            </div>

            <ManualOverrideDialog
                key={requestData ? `${requestData.customerId}_${requestData.siteId}` : undefined}
                requestData={requestData}
                onClose={() => setRequestData(undefined)}
            />
        </>
    );
};

const Message = styled("p")({
    marginTop: "20px",
});
