import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Employee } from '../../../dtos/employee';
import { AjaxResult } from '../../../enums/ajaxResult';
import { StandardTable } from '../../shared/table';
import { NewEmployeeForm } from './form';
import { Button, CircularProgress } from '@material-ui/core';
import { PageHeader } from '../../PageHeader';
import { ApiResult } from '../../../rest/baseApi';
import { OrgSelect } from '../../shared/OrgSelect';
import { useApi } from '../../useApi';
import { useSnackbar } from 'notistack';
import { EmployeeEditForm } from './editForm';
import linq from 'linq';
import { useOrgSiteContext } from '../../../hooks/useOrgSiteContext';
import { makeStyles, styled } from '@material-ui/styles';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';
import { EmployeesRequest } from '../../../rest/requestObjects/employeesRequest';
import { Pagination } from '../../shared/pagination';
import { ItemsPerPageInput } from '../../shared/itemsPerPageInput';
import { SearchBar } from '../events/searchBar';
import { UserLevel } from '../../../enums/userLevel';
import { HelpVideo } from '../../../enums/helpVideo';
import { ButtonsContainer } from '../../shared/ButtonsContainer';

import { useAuth } from "../../../features/auth/context";
import { EnrollBiometricsDialog } from "../../shared/EnrollBiometricsDialog";
import { EnrollBiometricsButton } from "../../shared/EnrollBiometricsButton";

import { EmployeesExportButton } from "./EmployeesExportButton";
import { useSiteColumn } from "./useSiteColumn";

export const EmployeesPage = () => {
    const { dealerId, siteChangeCallback, siteId, orgId } = useOrgSiteContext('employees');
    const classes = useStyles();
    const { roles, email, isGlobalAdmin, isDealerAdmin } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const { employeeApi } = useApi();

    const deassocciateSiteFromContext =
        roles.indexOf(UserLevel.SiteAdmin) !== -1 ||
        roles.indexOf(UserLevel.SiteEmployee) !== -1 ||
        roles.indexOf(UserLevel.OrgAdmin) !== -1;

    const { showConfirmDialog } = useConfirmDialog();

    const { showSiteColumn, sitesAreLoading, getSiteName } = useSiteColumn({ dealerId, orgId, siteId })

    const [employees, setEmployees] = useState<Employee[]>([]);
    const [newEmployeeFormIsVisible, setNewEmployeeFormIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(500);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [searchText, setSearchText] = useState<string>('');
    const search = useCallback((text: string) => setSearchText(text), []);
    const [employeeToEnroll, setEmployeeToEnroll] = useState<Employee | undefined>(undefined);

    const options = useMemo(
        () =>
        ({
            itemsPerPage,
            page: page + 1,
            searchText: searchText,
        } as EmployeesRequest),
        [page, itemsPerPage, searchText]
    );

    const loadEmployees = useCallback(() => {
        let promise: Promise<ApiResult<{ employees: Employee[]; numberOfPages: number }>>;
        if (orgId && siteId) {
            promise = employeeApi.getEmployeesForSite(orgId, siteId, options);
        } else if (orgId) {
            promise = employeeApi.getEmployeesForOrg(orgId, options);
        } else if (dealerId) {
            promise = employeeApi.getEmployeesForDealer(dealerId, options);
        } else {
            promise = employeeApi.getAllEmployees(options);
        }
        setIsLoading(true);
        promise.then((r) => {
            setIsLoading(false);
            if (r.result === AjaxResult.Success && r.data) {
                setEmployees(r.data.employees);
                setNumberOfPages(r.data.numberOfPages);
            }
        });
    }, [employeeApi, orgId, siteId, dealerId, options]);

    useEffect(() => {
        loadEmployees();
    }, [loadEmployees]);

    const deleteEmployee = (id: number) => {
        showConfirmDialog('Are you sure you want to delete this employee?').then((r) => {
            if (r) {
                employeeApi.delete(id).then((r) => {
                    if (r.result === AjaxResult.Success) {
                        enqueueSnackbar('Successfully deleted employee');
                        setEmployees((emps) => [...emps.filter((e) => e.id !== id)]);
                    } else if (r.result === AjaxResult.Forbidden) {
                        enqueueSnackbar(
                            'ERROR: You do not have permission to delete this employee'
                        );
                    } else {
                        enqueueSnackbar('ERROR: Failed to delete employee');
                    }
                });
            }
        });
    };

    const [employeeToEditId, setEmployeeToEditId] = useState<number | null>(null);
    const employeeToEdit = useMemo(
        () => linq.from(employees).firstOrDefault((e) => e.id === employeeToEditId),
        [employeeToEditId, employees]
    );
    const onEditSaved = () => {
        loadEmployees()
    };

    return (
        <>
            <PageHeader helpVideoNum={HelpVideo.Employee}>Employees</PageHeader>

            <OrgSelect
                selectedDealerId={dealerId}
                selectedOrgId={orgId}
                selectedSiteId={siteId || 0}
                dealerSelectIsEnabled={isGlobalAdmin}
                orgSelectIsEnabled={isGlobalAdmin || isDealerAdmin}
                includeAllSites
                bypassSiteContext={deassocciateSiteFromContext}
                onChange={(val: {
                    dealerId?: number | null;
                    orgId?: number | null;
                    siteId?: number | null;
                }) => siteChangeCallback(val.dealerId, val.orgId, val.siteId)}
            />

            <SearchBar
                defaultSearchText={searchText}
                search={search}
                labelText="Search Employees..."
            />

            <ButtonsContainer>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setNewEmployeeFormIsVisible(true)}
                >
                    Register New
                </Button>

                <EmployeesExportButton
                    orgId={orgId}
                    siteId={siteId}
                    dealerId={dealerId}
                    options={options}
                />
            </ButtonsContainer>

            <PaginationContainer>
                <Pagination {...{ setPage, page, pageCount: numberOfPages }} />
                <ItemsPerPageInput value={itemsPerPage} onChange={setItemsPerPage} />
            </PaginationContainer>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <StandardTable>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Employee ID</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Account Type</th>
                            <th>Last Login</th>
                            {showSiteColumn && (
                                <th>
                                    <div className={classes.siteColumnHeader}>
                                        <span>Site</span>
                                        {sitesAreLoading && (<CircularProgress size={12} />)}
                                    </div>
                                </th>
                            )}
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map((e) => (
                            <tr key={e.id}>
                                <td>{e.fullName}</td>
                                <td>{e.id}</td>
                                <td>{e.phoneNumber}</td>
                                <td>{e.email}</td>
                                <td>{e.address}</td>
                                <td>{e.roles.join(',')}</td>
                                <td>
                                    {e.lastLoginDate
                                        ? moment(e.lastLoginDate).format("M/DD/YYYY h:mm:ss A")
                                        : ""
                                    }
                                </td>
                                {showSiteColumn && (
                                    <td>{getSiteName(e.siteId)}</td>
                                )}
                                <td>
                                    <EnrollBiometricsButton
                                        user={e}
                                        onSetUserIdToEnroll={(userId) => {
                                            setEmployeeToEnroll(
                                                employees.find((ee) => ee.id === userId)
                                            );
                                        }}
                                        disabled={orgId === undefined}
                                    />
                                </td>
                                <td>
                                    {/* permissions are already such that users can only SEE employees
                                    that they can edit anyway, so we don't need logic to conditionally
                                    show the edit/delete buttons
                                */}
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        disabled={email === e.email}
                                        onClick={() => setEmployeeToEditId(e.id)}
                                    >
                                        Edit
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => deleteEmployee(e.id)}
                                        disabled={email === e.email}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </StandardTable>
            )}
            {newEmployeeFormIsVisible ? (
                <NewEmployeeForm
                    {...{ siteId, orgId, dealerId }}
                    closeForm={() => setNewEmployeeFormIsVisible(false)}
                    loadEmployees={loadEmployees}
                />
            ) : null}
            {employeeToEditId ? (
                <EmployeeEditForm
                    {...{ siteId, orgId, dealerId }}
                    closeEditForm={() => setEmployeeToEditId(null)}
                    employee={employeeToEdit}
                    onSaved={onEditSaved}
                />
            ) : null}
            <EnrollBiometricsDialog
                key={`${employeeToEnroll}`}
                user={employeeToEnroll}
                orgId={orgId}
                siteId={siteId}
                onClose={() => setEmployeeToEnroll(undefined)}
            />
        </>
    );
};

const PaginationContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

const useStyles = makeStyles({
    siteColumnHeader: {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        minWidth: "100px",
    }
});
