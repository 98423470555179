import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useCallback } from "react";

import { FoundSiteDto } from "../../../dtos/search";
import { useAuth } from "../../../features/auth/context";

import { useFoundSite } from "./useFoundSite";

const MAX_RESULT_ITEMS = 20;

interface TableProps {
    sites: FoundSiteDto[];
    onItemClick: () => void;
}

export function FoundSitesTable({ sites, onItemClick }: TableProps) {
    const { isGlobalAdmin, isDealerAdmin } = useAuth();

    const sitesToShow = sites
        .sort((a, b) => a.uniqueSiteId > b.uniqueSiteId ? 1 : -1)
        .slice(0, MAX_RESULT_ITEMS);

    const showDealerName = isGlobalAdmin;
    const showOrganizationName = isGlobalAdmin || isDealerAdmin;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell align="left">Site Unique ID</TableCell>
                    <TableCell align="left">Site Name</TableCell>
                    {showDealerName && (
                        <TableCell align="left">Dealer Name</TableCell>
                    )}
                    {showOrganizationName && (
                        <TableCell align="left">Organization Name</TableCell>
                    )}
                </TableRow>
            </TableHead>

            <TableBody>
                {sitesToShow.map((site) => (
                    <Row
                        key={site.uniqueSiteId}
                        site={site}
                        onItemClick={onItemClick}
                        showDealerName={showDealerName}
                        showOrganizationName={showOrganizationName}
                    />
                ))}
            </TableBody>
        </Table>
    );
}

interface RowProps {
    site: FoundSiteDto;
    onItemClick: () => void;
    showDealerName: boolean;
    showOrganizationName: boolean;
}

function Row({ site, onItemClick, showDealerName, showOrganizationName }: RowProps) {
    const { updateCurrentUrl } = useFoundSite(site);
    const { uniqueSiteId, siteName, organizationName, dealerName } = site;

    const handleClick = useCallback(() => {
        onItemClick();
        updateCurrentUrl();
    }, [onItemClick, updateCurrentUrl]);

    return (
        <TableRow
            key={uniqueSiteId}
            hover={true}
            onClick={handleClick}
            style={{ cursor: "pointer" }}
        >
            <TableCell align="left">{uniqueSiteId}</TableCell>
            <TableCell align="left">{siteName}</TableCell>
            {showDealerName && (
                <TableCell align="left">{dealerName}</TableCell>
            )}
            {showOrganizationName && (
                <TableCell align="left">{organizationName}</TableCell>
            )}
        </TableRow>
    );
}
