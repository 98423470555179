import { useEffect, useState } from "react";

import { AjaxResult } from "../../../../enums/ajaxResult";
import { useAuth } from "../../../../features/auth/context";
import { useApi } from "../../../useApi";

export function useUserDealerOption() {
    const [option, setOption] = useState<{ label: string; value: number }>();

    const { dealerId } = useAuth();
    const { dealerApi } = useApi();

    useEffect(() => {
        if (!dealerId) return;
        let cancel = false;
        dealerApi.getById(dealerId).then((r) => {
            if (!cancel && r.result === AjaxResult.Success && r.data) {
                setOption({ label: r.data.name, value: r.data.id });
            }
        });
        return () => {
            cancel = true;
        };
    }, [dealerId, dealerApi]);


    return option;
}
