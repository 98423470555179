import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core';
import { useIdleTimer } from 'react-idle-timer';
import {
    IDLE_TIMEOUT_ENABLED,
    IDLE_PROMPT_TIMEOUT_SECONDS,
    IDLE_TIMEOUT_SECONDS,
} from '../../constants';

import { useAuth } from "../../features/auth/context";

export const IdlePopup = ({ children }: { children?: any }) => {
    const { logout, refreshToken } = useAuth();

    const timeout = 1000 * IDLE_TIMEOUT_SECONDS;
    const promptTimeout = 1000 * IDLE_PROMPT_TIMEOUT_SECONDS;
    const eventsThrottle = 1000 * 10;
    const syncTimers = 200; //recommended value
    const [open, setOpen] = useState(false);
    const [remaining, setRemaining] = useState(0);

    const onPrompt = () => {
        // onPrompt will be called after the timeout value is reached
        setOpen(true);
        let now = new Date();
        let expiresDateTime = new Date(now.getTime() + promptTimeout + 1 * 1000);
        refreshToken(true, expiresDateTime);
        setRemaining(promptTimeout);
    };

    const onIdle = () => {
        // onIdle will be called after the promptTimeout is reached.
        logout();
    };

    //refresh token every X min (throttle delay), skip first call
    const onAction = () => {
        if (!isPrompted()) {
            console.debug('idle-timer-action: refresh token');
            refreshToken();
        }
    };

    const onMessage = (data: any) => {
        switch (data.action) {
            case 'ACTIVATE':
                setOpen(false);
                break;
            case 'LOGOUT_USER':
                logout();
                break;
            default:
            // no op
        }
    };

    const { isPrompted, activate, pause, message } = useIdleTimer({
        crossTab: true,
        syncTimers,
        timeout,
        promptTimeout,
        eventsThrottle,
        onPrompt,
        onIdle,
        onAction,
        onMessage,
    });

    const handleClose = () => {
        message({ action: 'LOGOUT_USER' }, true);
    };

    const handleConfirm = () => {
        activate();
        message({ action: 'ACTIVATE' }, true);
        refreshToken(true);
    };

    useEffect(() => {
        let timer: null | ReturnType<typeof setTimeout> = null;
        if (isPrompted() && remaining > 0) {
            timer = setTimeout(() => setRemaining(remaining - 1000), 1000);
        }
        return () => {
            if (timer != null) {
                clearTimeout(timer);
            }
        };
    }, [remaining, isPrompted]);

    useEffect(() => {
        if (!IDLE_TIMEOUT_ENABLED) {
            pause();
        }
    }, [pause]);

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            disableBackdropClick
        >
            <DialogTitle id="alert-dialog-title">You're about to be signed out</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    For security reasons, your connection times out after you've been inactive for a
                    while. Click Continue to stay signed in.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {' '}
                <Button color="primary" variant="contained" onClick={handleConfirm}>
                    Continue ({remaining / 1000})
                </Button>
                <Button onClick={handleClose} autoFocus>
                    Logoff
                </Button>
            </DialogActions>
        </Dialog>
    );
};;;
