import React, { useState, useEffect, useMemo } from 'react';
import * as core from '@material-ui/core';
import { Employee, EmployeeCreateDto } from '../../../dtos/employee';
import { UserLevel } from '../../../enums/userLevel';
import { FormField } from '../../shared/formField';
import { FormSection } from '../../shared/formSection';
import { PhoneNumberInput } from '../../shared/phoneNumberInput';
import { makeStyles } from '@material-ui/styles';
import { useApi } from '../../useApi';
import { AjaxResult } from '../../../enums/ajaxResult';
import { useSnackbar } from 'notistack';
import { ValidationErrors } from '../../shared/validationErrors';
import { DialogSelect } from '../../shared/dialogSelect';
import { ValueType } from 'react-select/src/types';
import { useUserTypeOptions } from './helpers';
import { OrgSelect } from '../../shared/OrgSelect';

import { useAuth } from "../../../features/auth/context";

export const EmployeeEditForm = ({
    closeEditForm,
    employee,
    onSaved,
}: {
    closeEditForm: () => void;
    employee: Employee;
    onSaved: (e: Employee) => void;
}) => {
    const { isGlobalAdmin, isDealerAdmin, isOrgAdmin, roles } = useAuth();
    const { employeeApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [edited, setEdited] = useState<EmployeeCreateDto | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setEdited({
            address: employee.address,
            phone: employee.phoneNumber,
            email: employee.email,
            firstName: employee.firstName,
            lastName: employee.lastName,
            siteId: employee.siteId,
            organizationId: employee.organizationId,
            dealerId: employee.dealerId,
            userLevel: employee.roles[0] as UserLevel, // this field won't be used anyway on this form
        });
    }, [employee]);
    const userLevelOptions = useUserTypeOptions(
        roles,
        edited !== undefined && edited !== null ? edited.organizationId : undefined,
        edited !== undefined && edited !== null ? edited.siteId : undefined,
        edited !== undefined && edited !== null ? edited.dealerId : undefined
    );
    const selectedUserLevelOption = useMemo(
        () => ({
            label: (edited ? edited.userLevel : '') || '',
            value: (edited ? edited.userLevel : '') || '',
        }),
        [edited]
    );

    const [errors, setErrors] = useState<string[]>([]);
    const save = () => {
        if (!edited) {
            return;
        }
        setLoading(true);
        employeeApi.editEmployee({ ...edited }).then((r) => {
            if (r.result === AjaxResult.Success) {
                enqueueSnackbar('Changes to employee have been saved');
                if (r.data) {
                    onSaved(r.data);
                }
                closeEditForm();
            } else if (r.messages) {
                setErrors(r.messages);
            } else {
                enqueueSnackbar('ERROR: failed to save changes to employee');
            }
            setLoading(false);
        });
    };

    const submitDisabled = loading || !edited || !edited.firstName || !edited.lastName;

    return (
        <core.Dialog
            className={classes.employeeDialog}
            fullWidth={true}
            maxWidth={'md'}
            open={true}
            onClose={closeEditForm}
        >
            <core.DialogContent className={classes.employeeDialogContent}>
                {employee && edited && (
                    <FormSection headerText={'Employee Info'}>
                        <div>{employee.email}</div>
                        <FormField label="First Name" required={true}>
                            <core.FormControl>
                                <core.TextField
                                    value={edited.firstName}
                                    onChange={(e) =>
                                        setEdited({ ...edited, firstName: e.currentTarget.value })
                                    }
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: { className: classes.input },
                                    }}
                                />
                            </core.FormControl>
                        </FormField>
                        <FormField label="Last Name" required={true}>
                            <core.FormControl>
                                <core.TextField
                                    value={edited.lastName}
                                    onChange={(e) =>
                                        setEdited({ ...edited, lastName: e.currentTarget.value })
                                    }
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: { className: classes.input },
                                    }}
                                />
                            </core.FormControl>
                        </FormField>
                        <FormField label="Phone">
                            <core.FormControl>
                                <core.TextField
                                    value={edited.phone}
                                    onChange={(e) =>
                                        setEdited({ ...edited, phone: e.currentTarget.value })
                                    }
                                    InputProps={{
                                        inputComponent: PhoneNumberInput,
                                        inputProps: { className: classes.input },
                                    }}
                                    variant="outlined"
                                />
                            </core.FormControl>
                        </FormField>
                        <FormField label="Address">
                            <core.FormControl>
                                <core.TextField
                                    value={edited.address}
                                    onChange={(e) =>
                                        setEdited({ ...edited, address: e.currentTarget.value })
                                    }
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: { className: classes.input },
                                    }}
                                />
                            </core.FormControl>
                        </FormField>
                        <FormField label="Account">
                            <core.FormControl className={classes.formControlForUserType}>
                                <DialogSelect
                                    options={userLevelOptions}
                                    value={selectedUserLevelOption}
                                    onChange={(
                                        value: ValueType<{ value: string; label: string }>
                                    ) => {
                                        const val = value as { value: string; label: string };
                                        if (!val) {
                                            return;
                                        }
                                        setEdited({ ...edited, userLevel: val.value as UserLevel });
                                    }}
                                />
                            </core.FormControl>
                        </FormField>
                        {(isGlobalAdmin || isDealerAdmin || isOrgAdmin) && (
                            <core.FormControl>
                                <OrgSelect
                                    ignoreOrgSiteContext={true}
                                    selectedDealerId={edited.dealerId}
                                    selectedOrgId={edited.organizationId}
                                    selectedSiteId={edited.siteId}
                                    dealerSelectIsEnabled={
                                        isGlobalAdmin
                                        && edited.userLevel !== UserLevel.GlobalAdmin
                                    }
                                    orgSelectIsEnabled={
                                        (isGlobalAdmin || isDealerAdmin)
                                        && [
                                            UserLevel.SiteAdmin,
                                            UserLevel.SiteEmployee,
                                            UserLevel.OrgAdmin,
                                        ].includes(edited.userLevel)
                                    }
                                    siteSelectIsEnabled={
                                        (isGlobalAdmin || isDealerAdmin || isOrgAdmin)
                                        && [UserLevel.SiteAdmin, UserLevel.SiteEmployee].includes(edited.userLevel)
                                    }
                                    bypassSiteContext={true}
                                    onChange={(val: {
                                        dealerId?: number | null;
                                        orgId?: number | null;
                                        siteId?: number | null;
                                    }) => {
                                        setEdited({
                                            ...edited,
                                            dealerId: val.dealerId || undefined,
                                            organizationId: val.orgId || undefined,
                                            siteId: val.siteId || undefined,
                                        });
                                    }}
                                    descriptive={true}
                                />
                            </core.FormControl>
                        )}
                        <ValidationErrors errors={errors} />
                    </FormSection>
                )}
            </core.DialogContent>
            <core.DialogActions className={classes.employeeDialogContent}>
                <core.Button color="primary" variant="contained" disabled={submitDisabled} onClick={save}>
                    {loading ? "Saving..." : "Save"}
                </core.Button>
                <core.Button color="secondary" variant="contained" onClick={closeEditForm}>
                    Cancel
                </core.Button>
            </core.DialogActions>
        </core.Dialog>
    );
};

const useStyles = makeStyles({
    input: {
        padding: '5px',
    },
    formControlForUserType: {
        minWidth: '300px',
    },
    employeeDialog: {
        maxWidth: '750px',
        margin: 'auto',
    },
    employeeDialogContent: {
        maxWidth: '700px',
    },
});
