import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const BASE_SERVER_URL: string =
    process.env.REACT_APP_ENV === 'production'
        ? 'https://paznic.app'
        : process.env.REACT_APP_ENV === 'staging'
            ? 'https://staging.paznic.app'
            : process.env.REACT_APP_ENV === 'test'
                ? 'https://paznic-test.devtestops.eu'
                : 'https://localhost:8001';

export const BLOB_STORAGE_URL: string =
    process.env.REACT_APP_ENV === 'production'
        ? 'https://paznic.app/content'
        : process.env.REACT_APP_ENV === 'staging'
            ? 'https://staging.paznic.app/content'
            : process.env.REACT_APP_ENV === 'test'
                ? 'https://paznic-test.devtestops.eu/content'
                : 'https://ssamanagementuidev.blob.core.windows.net';

export const ISSUE_COLLECTOR_SCRIPT: string =
    'https://balticsolutions.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/278rlr/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=b973add0';
export const ISSUE_COLLECTOR_ELEMENT_IDS = ["atlwdg-trigger", "atlwdg-blanket", "atlwdg-container"];

export const WHATS_NEW_PAGE_URL = "https://paznic.com/product-updates-3.html";

export const UI_THEME: ThemeOptions = {
    backgroundThemeColor1: '#fff',
    backgroundThemeColor2: '#eef2f8',
    fontThemeColor1: '#94a8ba',
    fontThemeColor2: '#465564',
    fontThemeColor3: '#192b3d',
    trimThemeColor1: '#3f51b5',
    dangerColor: '#f00',
};

export const IDLE_TIMEOUT_ENABLED: boolean = true; //show idle popup
export const IDLE_TIMEOUT_SECONDS: number = 60 * 15; //show idle popup in 15 min (PAS-1734)
export const IDLE_PROMPT_TIMEOUT_SECONDS: number = 30; //show idle popup for 30 seconds

export const REFRESH_TOKEN_THROTTLE_SECONDS: number = 180; //show idle popup

export const SSO_DOMAIN: string =
    process.env.REACT_APP_ENV === 'production'
        ? 'login.paznic.com'
        : process.env.REACT_APP_ENV === 'staging'
            ? 'login.staging.paznic.com'
            : process.env.REACT_APP_ENV === 'test'
                ? 'login.paznic-test.devtestops.eu'
                : getSafeEnvVariableValue("REACT_APP_CUSTOM_SSO_DOMAIN"); // set any for development in .env

export const SSO_TENANT: string =
    process.env.REACT_APP_ENV === 'production'
        ? 'paznic.com'
        : process.env.REACT_APP_ENV === 'staging'
            ? 'staging.paznic.com'
            : process.env.REACT_APP_ENV === 'test'
                ? 'paznic-test.devtestops.eu'
                : getSafeEnvVariableValue("REACT_APP_CUSTOM_SSO_TENANT"); // set any for development in .env

export const SSO_SCOPE: string =
    process.env.REACT_APP_ENV === 'production'
        ? 'https://paznic.onmicrosoft.com/management-api/ManagementApi.ReadWrite'
        : process.env.REACT_APP_ENV === 'staging'
            ? 'https://paznicstaging.onmicrosoft.com/management-api/ManagementApi.ReadWrite'
            : process.env.REACT_APP_ENV === 'test'
                ? 'https://paznictest.onmicrosoft.com/management-api/ManagementApi.ReadWrite'
                : getSafeEnvVariableValue("REACT_APP_CUSTOM_SSO_SCOPE"); // set any for development in .env

export const SSO_CLIENT_ID: string =
    process.env.REACT_APP_ENV === 'production'
        ? 'unknown'
        : process.env.REACT_APP_ENV === 'staging'
            ? '245ff3a9-e2ef-46ab-a301-0e42d0344264'
            : process.env.REACT_APP_ENV === 'test'
                ? '8cb18c2b-2616-4f2c-823c-fce75f613b87'
                : getSafeEnvVariableValue("REACT_APP_CUSTOM_SSO_CLIENT_ID"); // set any for development in .env

export const SSO_REDIRECT_URL: string =
    process.env.REACT_APP_CUSTOM_SSO_REDIRECT_URL
        ? process.env.REACT_APP_CUSTOM_SSO_REDIRECT_URL // set any for development in .env
        : BASE_SERVER_URL;

export const SSO_AUTHORITIES = {
    paznicCustom: `https://${SSO_DOMAIN}/${SSO_TENANT}/B2C_1A_PAZNICCUSTOMPOLICY`,
    changePassword: `https://${SSO_DOMAIN}/${SSO_TENANT}/B2C_1A_CHANGEPASSWORD`,
};

function getSafeEnvVariableValue(envVariableName: string) {
    const value = process.env[envVariableName];
    return value ? value : `${envVariableName}_not_set`;
}
