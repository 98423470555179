import { styled } from "@material-ui/styles";
import { Button, Dialog, DialogContent, DialogTitle, Paper } from "@material-ui/core";
import React, { useEffect } from "react";

import logo from "../../../images/paznic-logo.png";

import { Copyright } from "../../../components/Copyright";
import { useAuth } from "../context";
import { AuthState } from "../enums";

const LOGIN_BUTTON_LABELS: { [key in AuthState]: string } = {
    [AuthState.Idle]: "Login",
    [AuthState.GetToken]: "Loading...",
    [AuthState.Login]: "Logging in...",
    [AuthState.MainLogin]: "Logging in...",
    [AuthState.Error]: "Login failed. Try again",
};

export function AuthDialog() {
    const { state, login, getToken } = useAuth();

    const handleLoginWithSSO = (e: React.FormEvent) => {
        e.preventDefault();
        login();
    };

    useEffect(() => {
        // MSAL token is either in localstorage, either is requested after SSO auth redirect back to this page
        // Token is received based on connection speed. In case of 3G - in 3-5 seconds
        // Several attempts handles even slow connection

        let attempt = 1;
        getToken(attempt);

        const interval = setInterval(() => {
            if (attempt < 5) {
                attempt++;
                getToken(attempt);
            } else {
                clearInterval(interval);
            }
        }, 2000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getToken]);

    return (
        <Dialog
            open={true}
            fullWidth={true}
            PaperComponent={StyledPaper}
        >
            <DialogTitle>
                <LogoImage src={logo} alt="Paznic logo" />
            </DialogTitle>

            <StyledDialogContent>
                <StyledButton
                    color="primary"
                    variant="contained"
                    onClick={handleLoginWithSSO}
                    disabled={![AuthState.Idle, AuthState.Error].includes(state)}
                >
                    {LOGIN_BUTTON_LABELS[state]}
                </StyledButton>
            </StyledDialogContent>

            <CopyrightContainer>
                <Copyright />
            </CopyrightContainer>
        </Dialog>
    );
};

const StyledPaper = styled(Paper)({
    padding: "30px 0px 20px 0px",
    maxWidth: "520px",
});

const LogoImage = styled("img")({
    display: "block",
    margin: "auto",
    width: "200px",
});

const StyledDialogContent = styled(DialogContent)({
    margin: "auto",
    marginTop: "15px",
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
});

const StyledButton = styled(Button)({
    minWidth: "210px"
});

const CopyrightContainer = styled("div")({
    textAlign: "center",
    fontSize: "11px",
});
