import { UserDataFromToken } from "./types";

export const EMPTY_USER_DATA: UserDataFromToken = {
    username: null,
    email: null,
    siteId: null,
    roles: [],
    orgId: null,
    dealerId: null,
    eulaAccepted: null,
    isDealerActive: null,
    isOrgActive: null,
    isSiteActive: null,
};
