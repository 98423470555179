import { EmployeeForView } from '../dtos/employee';
import { AxiosApi, ApiResult } from './axiosApi';

export class AuthApi extends AxiosApi<{}> {
    constructor(baseUri: string, enqueueSnackbar?: any) {
        super(baseUri, 'auth', undefined, enqueueSnackbar);
    }

    public getToken = (
        email?: string,
        password?: string
    ): Promise<
        ApiResult<{
            sessionId: string;
            username: string;
            accessToken: string;
            refreshToken: string;
            refreshTokenExpiration: string;
        }>
    > =>
        this.genericRequest({
            data: email && password ? { email, password } : undefined,
            extraPath: 'token',
            method: 'POST',
            skipTokenRefresh: true,
        });

    public refreshToken = (
        accessToken: string,
        refreshToken: string
    ): Promise<
        ApiResult<{
            accessToken: string;
            refreshToken: string;
            refreshTokenExpiration: string;
        }>
    > =>
        this.genericRequest({
            data: { accessToken, refreshToken },
            extraPath: 'refreshToken',
            method: 'POST',
            skipTokenRefresh: true,
        });

    public getUserInfo = (): Promise<ApiResult<EmployeeForView>> =>
        this.genericRequest({
            method: 'GET',
            extraPath: 'userInfo',
        });

    public regenerateUserPinCode = (): Promise<ApiResult<EmployeeForView>> =>
        this.genericRequest({
            method: 'POST',
            extraPath: 'regeneratePinCode',
        });
}
