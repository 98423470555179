import React, { useState, useReducer, useEffect, useCallback, useMemo } from 'react';
import useRouter from 'use-react-router';
import { AjaxResult } from '../../../enums/ajaxResult';
import { PageHeader } from '../../PageHeader';
import * as material from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Paper, Button } from '@material-ui/core';
import * as State from './state';
import { LeaseForm } from './leaseForm';
import * as Selectors from './state/selectors';
import Select from 'react-select';
import * as linq from 'linq';
import { ValueType } from 'react-select/src/types';
import { CustomerForm } from '../customers/form';
import { Customer } from '../../../dtos/customer';
import { useSnackbar } from 'notistack';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { useApi } from '../../useApi';
import { EndDateEditForm } from './endDateEditForm';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';
import { useOrgSiteContext } from '../../../hooks/useOrgSiteContext';

import { useAuth } from "../../../features/auth/context";

const initialState: State.State = {
    box: null,
    customers: [],
    owners: [],
};

export const BoxDetailPage = () => {
    const { boxApi, customerApi, siteApi, leaseApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { showConfirmDialog } = useConfirmDialog();
    const classes = useStyles();
    const { match } = useRouter();
    const id = (match.params as { boxId: number }).boxId;
    const { orgId: contextOrgId, siteId: contextSiteId } = useOrgSiteContext('boxDetail');
    const assignedOrgAndSite = useAuth();

    const orgId = useMemo(
        () => contextOrgId || assignedOrgAndSite.orgId,
        [assignedOrgAndSite.orgId, contextOrgId]
    );
    const siteId = useMemo(
        () => contextSiteId || assignedOrgAndSite.siteId,
        [contextSiteId, assignedOrgAndSite.siteId]
    );

    // state managed by reducer
    const [state, dispatch] = useReducer(State.reducer, initialState);
    const { box, customers: allCustomers, owners } = state;

    // other state
    const [leaseFormIsVisible, setLeaseFormIsVisible] = useState(false);
    const [selectedCustomerId, selectCustomer] = useState<number | null>(null);
    const [addingOwner, setAddingOwner] = useState<boolean>(false);
    const [registerCustomerFormIsVisible, setRegisterCustomerFormIsVisible] =
        useState<boolean>(false);
    const [showAllOrgCustomers, setShowAllOrgCustomers] = useState<boolean>(false);

    const fetchBoxData = useCallback(() => {
        boxApi.getById(id).then((r) => {
            if (r.result === AjaxResult.Success && r.data) {
                dispatch(State.Actions.loadBox(r.data));
            }
        });
        boxApi.getOwners(id).then((r) => {
            if (r.result === AjaxResult.Success && r.data) {
                dispatch(State.Actions.loadOwners(r.data));
            }
        });
    }, [id, boxApi]);

    useEffect(() => {
        fetchBoxData();
    }, [fetchBoxData]);

    useEffect(() => {
        if (box) {
            const effectiveOrgId = box.orgId ? box.orgId : orgId || 0;
            if (showAllOrgCustomers) {
                customerApi.listCustomersForOrg(effectiveOrgId).then((r) => {
                    if (r.result === AjaxResult.Success && r.data) {
                        dispatch(State.Actions.loadCustomers(r.data));
                    } else {
                        enqueueSnackbar('ERROR: Failed to get list of customers for organization');
                    }
                });
            } else {
                customerApi.listCustomersForSite(effectiveOrgId, box.siteId).then((r) => {
                    if (r.result === AjaxResult.Success && r.data) {
                        dispatch(State.Actions.loadCustomers(r.data));
                    } else {
                        enqueueSnackbar('ERROR: Failed to get list of customers for site');
                    }
                });
            }
        }
    }, [box, orgId, showAllOrgCustomers, enqueueSnackbar, customerApi, siteApi]);

    const customerOptions = Selectors.useCustomerOptions(allCustomers, owners as Customer[]);
    const selectedCustomer = useMemo(
        () =>
            linq
                .from(customerOptions)
                .select((o) => ({ label: o.fullName, value: o.id }))
                .firstOrDefault((o) => o.value === selectedCustomerId) || null,
        [customerOptions, selectedCustomerId]
    );

    const addOwner = () => {
        setAddingOwner(true);
        if (!selectedCustomerId) {
            return;
        }
        if (!owners.length) {
            setLeaseFormIsVisible(true);
            return;
        }
        boxApi.addOwner(id, selectedCustomerId).then((r) => {
            setAddingOwner(false);
            if (r.result === AjaxResult.Success) {
                let selectedCustomer = customerOptions.find((c) => c.id === selectedCustomerId);
                if (selectedCustomer === undefined) {
                    return;
                }
                enqueueSnackbar('Owner added', { variant: 'success' });
                dispatch(State.Actions.addOwner(selectedCustomer));
                selectCustomer(null);
                fetchBoxData();
            } else {
                alert('ERROR: failed to add owner');
            }
        });
    };
    const deleteOwner = (customerId: number) => {
        boxApi.removeOwner(id, customerId).then((r) => {
            if (r.result === AjaxResult.Success) {
                enqueueSnackbar('Owner removed', { variant: 'success' });
                dispatch(State.Actions.deleteOwner(customerId));
                fetchBoxData();
            } else {
                alert('ERROR: failed to remove owner');
            }
        });
    };
    const registerOwnerCallback = (customer: Customer) => {
        dispatch(State.Actions.addCustomer(customer));
        selectCustomer(customer.id);
        setRegisterCustomerFormIsVisible(false);
    };
    const setNotes = (value: string) => {
        dispatch(State.Actions.setNotes(value));
    };
    const syncNotes = () => {
        if (!box) {
            return;
        }
        boxApi.setNotes(id, box.notes).then((r) => {
            if (r.result !== AjaxResult.Success) {
                alert('failure');
            } else {
                enqueueSnackbar('Notes saved to server', { variant: 'success' });
            }
        });
    };

    const [leaseEditFormIsVisible, setLeaseEditFormIsVisible] = useState(false);
    const saveEndDate = (date: Date | null | undefined) => {
        if (!box) {
            return;
        }
        leaseApi.changeLeaseEndDate(box.id, date).then((r) => {
            if (r.result === AjaxResult.Success) {
                enqueueSnackbar('New lease end date saved');
                setLeaseEditFormIsVisible(false);
                fetchBoxData();
            } else if (r.result === AjaxResult.Forbidden) {
                enqueueSnackbar("ERROR: You do not have permission to edit this box's lease");
            } else {
                enqueueSnackbar('ERROR: Failed to save new lease end date');
            }
        });
    };

    const deleteCurrentLease = () => {
        showConfirmDialog(`Are you sure you want do delete this box's current lease?`).then(
            (confirmed) => {
                if (confirmed) {
                    boxApi.deleteCurrentLease(id).then((r) => {
                        if (r.result === AjaxResult.Success) {
                            enqueueSnackbar('Lease was deleted');
                            fetchBoxData();
                        } else {
                            enqueueSnackbar('ERROR: Failed to delete lease');
                        }
                    });
                }
            }
        );
    };

    return (
        <>
            {!box ? null : (
                <>
                    <LeaseForm
                        leaseFormIsVisible={leaseFormIsVisible}
                        setLeaseFormIsVisible={setLeaseFormIsVisible}
                        boxId={id}
                        selectedCustomerId={selectedCustomerId}
                        fetchBoxData={fetchBoxData}
                        formCloseCallback={() => { setAddingOwner(false); selectCustomer(null); }}
                    />
                    {registerCustomerFormIsVisible ? (
                        <material.Dialog
                            open={true}
                            onClose={() => setRegisterCustomerFormIsVisible(false)}
                        >
                            <material.DialogContent>
                                <CustomerForm
                                    callback={registerOwnerCallback}
                                    close={() => setRegisterCustomerFormIsVisible(false)}
                                    orgId={box.orgId}
                                    siteId={box.siteId}
                                />
                            </material.DialogContent>
                        </material.Dialog>
                    ) : null}
                    <PageHeader>Box #{box.number}</PageHeader>
                    <div className={classes.basicInfo}>
                        <div>Box Number:</div>
                        <div>{box.number}</div>
                        <div>Fingerprint Access Disabled:</div>
                        <div>[Placeholder]</div>
                        <div>Size:</div>
                        <div>{box.size}</div>
                        <div>Lease End Date:</div>
                        <div>
                            {box.leaseEndDate ? (
                                <span className={box.isExpired ? classes.leaseExpired : undefined}>
                                    {new Date(box.leaseEndDate).toLocaleDateString()}
                                </span>
                            ) : owners.length ? (
                                'No expiration'
                            ) : null}
                        </div>
                        <div>Multiple Owners:</div>
                        <div>{owners.length > 1 ? 'Yes' : 'No'}</div>
                        <div>Location:</div>
                        <div>{box.location}</div>
                    </div>
                    <header className={classes.ownersHeader}>
                        <h2>Owner Info</h2>
                        <div>
                            <material.FormControlLabel
                                control={
                                    <material.Checkbox
                                        value={showAllOrgCustomers}
                                        color={'primary'}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            setShowAllOrgCustomers(event.target.checked)
                                        }
                                    />
                                }
                                label="Show All Organization Customers"
                            />
                            <Select
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        whiteSpace: 'pre-wrap',
                                    }),
                                }}
                                TextFieldProps={{ label: 'Select Owner...' }}
                                options={
                                    customerOptions.map((o) => ({
                                        label: `${o.fullName}\n${o.phone}\n${o.email}`,
                                        value: o.id,
                                    })) as { value: number; label: string }[]
                                }
                                value={selectedCustomer}
                                onChange={(value: ValueType<{ value: number; label: string }>) => {
                                    const val = value as { value: number; label: string };
                                    selectCustomer(val ? val.value : 0);
                                }}
                                placeholder="Select Owner..."
                            />
                            <div>
                                <material.Button
                                    variant="contained"
                                    color="primary"
                                    onClick={addOwner}
                                    disabled={!selectedCustomerId || addingOwner}
                                    className={classes.ownerButton}
                                >
                                    Add Owner
                                </material.Button>
                                <material.Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setRegisterCustomerFormIsVisible(true)}
                                    className={classes.ownerButton}
                                >
                                    Register Owner
                                </material.Button>
                            </div>
                        </div>
                    </header>
                    <material.Divider variant="fullWidth"></material.Divider>
                    {!owners.length ? (
                        <div>
                            To get started, add a new owner to this box or register a new user
                        </div>
                    ) : (
                        <div>
                            <Button
                                className={classes.formButton}
                                onClick={() => setLeaseEditFormIsVisible(true)}
                                variant="contained"
                                color="primary"
                            >
                                Edit Lease End Date
                            </Button>
                            <Button
                                className={classes.formButton}
                                variant="contained"
                                color="secondary"
                                onClick={deleteCurrentLease}
                            >
                                Delete Lease
                            </Button>
                        </div>
                    )}
                    {leaseEditFormIsVisible && box ? (
                        <EndDateEditForm
                            box={box}
                            saveDate={saveEndDate}
                            close={() => setLeaseEditFormIsVisible(false)}
                        />
                    ) : null}
                    {owners.map((o) => (
                        <Paper className={classes.ownerCard} key={o.id}>
                            <h3>
                                <span>{o.fullName}</span>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.deleteButton}
                                    onClick={() => deleteOwner(o.id)}
                                >
                                    DELETE
                                </Button>
                            </h3>
                            <div>
                                <label>Phone:</label>
                                {o.phone}
                            </div>
                            <div>
                                <label>Email:</label>
                                {o.email}
                            </div>
                            <div>
                                <label>Address:</label>
                                {o.address}
                            </div>
                        </Paper>
                    ))}
                    <h2>Box Notes</h2>
                    <material.Divider variant="fullWidth"></material.Divider>
                    <material.TextField
                        value={box.notes}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setNotes(e.currentTarget.value)
                        }
                        onBlur={() => syncNotes()}
                        multiline
                        fullWidth
                        rows={5}
                        variant="filled"
                        className={classes.notesField}
                    />
                </>
            )}
            <div>
                <material.Link
                    component={RouterLink}
                    to={`${orgId ? `/organizations/${orgId}${siteId ? `/sites/${siteId}` : ''}` : ''
                        }/boxes`}
                >
                    Back to Boxes List
                </material.Link>
            </div>
        </>
    );
};

const useStyles = material.makeStyles((theme: ThemeOptions) => ({
    basicInfo: {
        backgroundColor: theme.backgroundThemeColor1,
        color: 'black',
        display: 'grid',
        gridTemplateColumns: '200px 100px 300px 100px 200px 100px',
        marginBottom: '20px',
        rowGap: '10px',
        '& > div': {
            padding: '10px',
        },
        '& > div:nth-child(odd)': {
            fontWeight: 'bold',
        },
    },
    leaseExpired: {
        color: theme.dangerColor,
    },
    ownersHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        '& h2': {
            flexGrow: 1,
        },
        '& > div': {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            '& > div': {
                flexGrow: 1,
            },
        },
    },
    formButton: {
        marginTop: '10px',
        marginRight: '20px',
    },
    ownerButton: {
        marginLeft: '40px',
    },
    ownerCard: {
        padding: '20px',
        margin: '30px 0',
        width: '50%',

        '& h3': {
            display: 'flex',
            justifyContent: 'space-between',

            '& span': {
                display: 'inline-block',
            },
        },

        '& label': {
            fontWeight: 'bold',
            marginRight: '5px',
        },
    },
    deleteButton: {
        display: 'inline-block',
    },
    notesField: {
        backgroundColor: theme.backgroundThemeColor1,
    },
    dateEditSection: {
        display: 'flex',
        marginTop: '20px',

        '& > div': {
            marginRight: '20px',
        },
    },
}));
