import React, { useContext, useMemo } from 'react';
import logo from '../images/paznic-logo.png';
import { StyledLink } from './shared/styledLink';
import { UserLevel } from '../enums/userLevel';
import { Drawer } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import * as navIcons from '../images/navIcons';
import useReactRouter from 'use-react-router';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { OrgSiteContext } from './orgSiteContext';
import { NotificationsNone, Schedule } from '@material-ui/icons';
import { navLinkUrl } from '../utils';

import { useAuth } from "../features/auth/context";

export const NavBar = () => {
    const { location } = useReactRouter();
    const classes = useStyles();
    const assignedOrgAndSite = useAuth();
    const orgSiteContext = useContext(OrgSiteContext);
    const dealerId = useMemo(
        () => orgSiteContext.dealerId || assignedOrgAndSite.dealerId,
        [assignedOrgAndSite.dealerId, orgSiteContext.dealerId]
    );
    const orgId = useMemo(
        () => orgSiteContext.orgId || assignedOrgAndSite.orgId,
        [assignedOrgAndSite.orgId, orgSiteContext.orgId]
    );
    const siteId = useMemo(
        () => orgSiteContext.siteId || assignedOrgAndSite.siteId,
        [orgSiteContext.siteId, assignedOrgAndSite.siteId]
    );
    return (
        <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            anchor="left"
            className={classes.drawer}
        >
            <img alt="" src={logo} className={classes.logo} />
            <LinkList>
                <NavbarLink
                    altText="home page icon"
                    exact
                    iconSrc={{ active: navIcons.home, inactive: navIcons.homeInactive }}
                    path={navLinkUrl('home', dealerId, orgId, siteId)}
                    linkText="Home"
                    isActive={location.pathname.indexOf('home') !== -1 || location.pathname === '/'}
                />
                <NavbarLink
                    altText="customers page icon"
                    iconSrc={{
                        active: navIcons.customersActive,
                        inactive: navIcons.customers,
                    }}
                    path={navLinkUrl('customers', dealerId, orgId, siteId)}
                    linkText="Customers"
                    isActive={location.pathname.indexOf('customers') !== -1}
                />

                <NavbarLink
                    altText="boxes page icon"
                    iconSrc={{ active: navIcons.boxesActive, inactive: navIcons.boxes }}
                    path={navLinkUrl('boxes', dealerId, orgId, siteId)}
                    linkText="Boxes"
                    isActive={location.pathname.indexOf('boxes') !== -1}
                />
                <NavbarLink
                    altText="manual override page icon"
                    iconSrc={{ active: navIcons.wrenchActive, inactive: navIcons.wrench }}
                    path={navLinkUrl('manual-override', dealerId, orgId, siteId)}
                    linkText="Manual Override"
                    isActive={location.pathname.indexOf('manual-override') !== -1}
                />
                <NavbarLink
                    altText="scheduled jobs page icon"
                    iconSrc={{ active: navIcons.reportActive, inactive: navIcons.report }}
                    path={navLinkUrl('scheduled-jobs', dealerId, orgId, siteId)}
                    iconOverride={{
                        active: <Schedule color="primary" />,
                        inactive: <Schedule />,
                    }}
                    linkText="Scheduled Jobs"
                    authRoles={[
                        UserLevel.GlobalAdmin,
                    ]}
                    isActive={location.pathname.indexOf('scheduled-jobs') !== -1}
                />
                <NavbarLink
                    altText="employees page icon"
                    iconSrc={{
                        active: navIcons.customersActive,
                        inactive: navIcons.customers,
                    }}
                    path={navLinkUrl('employees', dealerId, orgId, siteId)}
                    linkText="Employees"
                    authRoles={[
                        UserLevel.GlobalAdmin,
                        UserLevel.DealerAdmin,
                        UserLevel.OrgAdmin,
                        UserLevel.SiteAdmin,
                    ]}
                    isActive={location.pathname.indexOf('employees') !== -1}
                />
                <NavbarLink
                    altText="events page icon"
                    iconSrc={{ active: navIcons.reportActive, inactive: navIcons.report }}
                    path={navLinkUrl('reports', dealerId, orgId, siteId)}
                    linkText="Reports"
                    authRoles={[
                        UserLevel.GlobalAdmin,
                        UserLevel.DealerAdmin,
                        UserLevel.OrgAdmin,
                        UserLevel.SiteAdmin,
                        UserLevel.SiteEmployee,
                    ]}
                    isActive={location.pathname.indexOf('reports') !== -1}
                />
                <NavbarLink
                    altText="alarms page icon"
                    iconSrc={{ active: navIcons.reportActive, inactive: navIcons.report }}
                    iconOverride={{
                        active: <NotificationsNone color="primary" />,
                        inactive: <NotificationsNone />,
                    }}
                    path={navLinkUrl('alarms', dealerId, orgId, siteId)}
                    linkText="Alarms"
                    authRoles={[
                        UserLevel.GlobalAdmin,
                        UserLevel.DealerAdmin,
                        UserLevel.OrgAdmin,
                        UserLevel.SiteAdmin,
                        UserLevel.SiteEmployee,
                    ]}
                    isActive={location.pathname.indexOf('alarms') !== -1}
                />
                <NavbarLink
                    altText="dealers page icon"
                    iconSrc={{ active: navIcons.reportActive, inactive: navIcons.report }}
                    path="/dealers"
                    linkText="Dealers"
                    authRoles={[UserLevel.GlobalAdmin]}
                    isActive={location.pathname === '/dealers'}
                />
                <NavbarLink
                    altText="orgs page icon"
                    iconSrc={{ active: navIcons.reportActive, inactive: navIcons.report }}
                    path={navLinkUrl("organizations", dealerId)}
                    linkText="Organizations"
                    authRoles={[UserLevel.GlobalAdmin, UserLevel.DealerAdmin]}
                    isActive={/organizations$/.test(location.pathname)}
                />
                <NavbarLink
                    altText="sites page icon"
                    exact
                    iconSrc={{ active: navIcons.reportActive, inactive: navIcons.report }}
                    path={navLinkUrl('sites', dealerId, orgId, siteId)}
                    linkText="Sites"
                    authRoles={[
                        UserLevel.GlobalAdmin,
                        UserLevel.DealerAdmin,
                        UserLevel.OrgAdmin,
                        UserLevel.SiteAdmin,
                        UserLevel.SiteEmployee,
                    ]}
                    isActive={/sites$/.test(location.pathname)}
                />
            </LinkList>
        </Drawer>
    );
};


const drawerWidth = 336;

const useStyles = makeStyles((theme: ThemeOptions) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.backgroundThemeColor1,
        zIndex: 1,
    },
    logo: {
        marginTop: '10px',
        display: 'block',
        margin: '0 auto',
        width: 200,
    },
}));

const LinkList = styled('ul')({
    listStyleType: 'none',
    listStylePosition: 'inside',
    padding: 0,

    '& img': {
        marginRight: '40px',
        width: '16px',
    },
    '& svg': {
        marginRight: '40px',
    },
});

const NavbarLink = ({
    path,
    iconSrc,
    iconOverride,
    exact,
    altText,
    linkText,
    authRoles,
    isActive,
}: {
    exact?: boolean;
    path: string;
    iconSrc: { inactive: string; active: string };
    iconOverride?: { inactive: JSX.Element; active: JSX.Element };
    altText: string;
    linkText: string;
    authRoles?: UserLevel[];
    isActive: boolean;
}) => {
    const { roles } = useAuth();
    const userIsAuthorized = useMemo(
        () => (authRoles ? roles.some((r) => authRoles.indexOf(r as UserLevel) !== -1) : true),
        [authRoles, roles]
    );
    if (!userIsAuthorized) {
        return null;
    }
    return (
        <NavLinkContainer isActive={isActive}>
            <NavLinkStyled to={path} exact={exact}>
                <div>
                    {iconOverride ? (
                        isActive ? (
                            iconOverride.active
                        ) : (
                            iconOverride.inactive
                        )
                    ) : (
                        <img src={isActive ? iconSrc.active : iconSrc.inactive} alt={altText} />
                    )}
                    {linkText}
                </div>
            </NavLinkStyled>
        </NavLinkContainer>
    );
};

interface NavLinkContainerProps {
    isActive: boolean;
    children?: any;
    className?: string;
}

const NavLinkContainer = styled(({ isActive, children, className }: NavLinkContainerProps) => (
    <li className={className}>{children}</li>
))({
    borderLeft: (props: any) => (props.isActive ? '7px solid #0075ff' : '#fff'),
    backgroundColor: (props: any) => (props.isActive ? '#f2f8ff' : 'inherit'),
    color: (props: any) => (props.isActive ? '#0075ff' : 'inherit'),
});

const NavLinkStyled = styled(StyledLink)(({ theme }: { theme: ThemeOptions }) => ({
    boxSizing: 'border-box',
    display: 'block',
    fontSize: '18px',
    padding: '30px 0',

    '& div': {
        margin: 'auto',
        width: '200px',
    },
}));
