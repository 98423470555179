import { useEffect, useMemo, useState } from "react";

import { Organization } from "../../../../dtos/organization";
import { AjaxResult } from "../../../../enums/ajaxResult";
import { useAuth } from "../../../../features/auth/context";
import { useApi } from "../../../useApi";

export function useOrganizationOptions(
    selectedOrganizationId: number | null,
    dealerId: number | null,
    canGetOptions: boolean,
) {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { orgApi } = useApi();
    const {
        isGlobalAdmin,
        isDealerAdmin,
        dealerId: assignedDealerId,
    } = useAuth();

    useEffect(() => {
        if (!canGetOptions) return;
        if (!isGlobalAdmin && !isDealerAdmin) return;
        const effectiveDealerId = dealerId || assignedDealerId;
        if (effectiveDealerId === null) {
            setOrganizations([]);
            return;
        }
        let cancel = false;
        setIsLoading(true);
        orgApi.getForDealer(effectiveDealerId).then((r) => {
            if (!cancel && r.result === AjaxResult.Success && r.data) {
                setOrganizations(r.data);
            }
            setIsLoading(false);
        });
        return () => {
            cancel = true;
        };
    }, [
        canGetOptions,
        orgApi,
        isGlobalAdmin,
        isDealerAdmin,
        assignedDealerId,
        dealerId,
    ]);

    const organizationOptions = useMemo(() => {
        return organizations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(it => ({ label: it.name, value: it.id }));
    }, [organizations]);

    const selectedOrganizationOption = useMemo(() => {
        return organizationOptions.find(it => it.value === selectedOrganizationId);
    }, [organizationOptions, selectedOrganizationId]);

    return { organizationOptions, selectedOrganizationOption, isLoading };
}
