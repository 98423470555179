import jwt_decode from "jwt-decode";
import { UserDataFromToken } from "./types";
export function getUserDataFromToken(jwt: string): UserDataFromToken {
    const decoded: any = jwt_decode(jwt);
    const usernameKey = Object.keys(decoded).find((k) => k.endsWith('nameidentifier'));
    const rolesKey = Object.keys(decoded).find((k) => k.endsWith('role'));
    const emailKey = Object.keys(decoded).find((k) => k.endsWith('emailaddress'));
    return {
        username: usernameKey ? decoded[usernameKey] as string : null,
        email: emailKey ? decoded[emailKey] as string : null,
        dealerId: decoded['dealerId'] ? parseInt(decoded['dealerId']) : null,
        orgId: decoded['organizationId'] ? parseInt(decoded['organizationId']) : null,
        siteId: decoded['siteId'] ? parseInt(decoded['siteId']) : null,
        eulaAccepted: decoded['eulaAccepted'] === '1',
        isDealerActive: decoded['isDealerActive'] === "False" ? false : true,
        isOrgActive: decoded['isOrgActive'] === "False" ? false : true,
        isSiteActive: decoded['isSiteActive'] === "False" ? false : true,
        roles: rolesKey
            ? Array.isArray(decoded[rolesKey])
                ? (decoded[rolesKey] as string[])
                : ([decoded[rolesKey]] as string[])
            : [],
    };
}
