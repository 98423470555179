import { useEffect, useState } from "react";

export function useTabVisibility() {
    const [isTabVisible, setIsTabVisible] = useState(!document.hidden);
    const [isTabFocused, setIsTabFocused] = useState(document.hasFocus());

    useEffect(() => {
        // Callback function to handle visibility change
        const handleVisibilityChange = () => {
            setIsTabVisible(!document.hidden);
        };

        // Callback function to handle focus change
        const handleFocusChange = () => {
            setIsTabFocused(document.hasFocus());
        };

        // Check if the Page Visibility API is supported by the browser
        if (typeof document.hidden !== "undefined") {
            // Add the event listeners for visibility change and focus change
            document.addEventListener("visibilitychange", handleVisibilityChange);
            window.addEventListener("focus", handleFocusChange);
            window.addEventListener("blur", handleFocusChange);
        }

        // Clean up the event listeners on unmount
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            window.removeEventListener("focus", handleFocusChange);
            window.removeEventListener("blur", handleFocusChange);
        };
    }, []);

    return { isTabVisible, isTabFocused };
}
