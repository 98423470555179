import React, { useState, useMemo, useRef, useEffect } from 'react';
import { AjaxResult } from '../../../enums/ajaxResult';
import { ValidationErrors } from '../../shared/validationErrors';
import {
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControl,
} from '@material-ui/core';
import { UserLevel } from '../../../enums/userLevel';
import { ValueType } from 'react-select/src/types';
import { useSnackbar } from 'notistack';
import { useApi } from '../../useApi';
import { DialogSelect } from '../../shared/dialogSelect';
import { PhoneNumberInput } from '../../shared/phoneNumberInput';
import { FormField } from '../../shared/formField';
import { makeStyles } from '@material-ui/styles';
import { FormSection } from '../../shared/formSection';
import { useUserTypeOptions } from './helpers';

import { useAuth } from "../../../features/auth/context";

/**
 * NOTE: which UserLevel options are available in the dropdown depends on whether
 * siteId, orgId, and/or dealerId are defined
 */
export const NewEmployeeForm = ({
    orgId,
    siteId,
    dealerId,
    isAddDealerForm,
    closeForm,
    loadEmployees,
}: {
    siteId?: number;
    orgId?: number;
    dealerId?: number;
    isAddDealerForm?: boolean;
    closeForm: () => void;
    loadEmployees: () => void;
}) => {
    const classes = useStyles();
    const { roles } = useAuth();
    const { employeeApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [address, setAddress] = useState('');
    const [errors, setErrors] = useState<string[]>([]);
    const userLevels = useUserTypeOptions(roles, orgId, siteId, dealerId);
    const [loading, setLoading] = useState<boolean>(false);
    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(true);
    const [reAddDialogOpen, setReAddDialogOpen] = useState<boolean>(false);

    const [selectedUserLevel, setSelectedUserLevel] = useState<UserLevel | null>(null);
    useEffect(() => {
        if (userLevels.length) {
            setSelectedUserLevel(userLevels[0].value as UserLevel);
        }
    }, [userLevels]);
    const dialogContentRef = useRef(null);

    const submit = () => {
        if (!selectedUserLevel) {
            return;
        } else if (email !== confirmEmail) {
            setErrors([
                ...errors,
                'Emails do not match. Please ensure confirm email matches original.',
            ]);
            return;
        }

        setLoading(true);
        employeeApi
            .create({
                email,
                phone,
                firstName,
                lastName,
                address,
                siteId,
                organizationId: siteId ? undefined : orgId,
                dealerId: orgId ? undefined : dealerId,
                userLevel: selectedUserLevel,
            })
            .then((r) => {
                if (r.result === AjaxResult.Success) {
                    alert('New employee has been created');
                    loadEmployees();
                    closeForm();
                } else if (r.result === AjaxResult.Forbidden) {
                    enqueueSnackbar(
                        `ERROR: You do not have permission to create a ${selectedUserLevel} user in this context`
                    );
                } else if (r.result === AjaxResult.Duplicate) {
                    setCreateDialogOpen(false);
                    setReAddDialogOpen(true);
                } else {
                    enqueueSnackbar('ERROR: Failed to create employee');
                    if (r.messages) {
                        setErrors(r.messages);
                    }
                }
                setLoading(false);
            });
    };

    const submitRestore = () => {
        if (!selectedUserLevel) {
            return;
        }
        setLoading(true);
        employeeApi.restoreEmployee({
            email,
            phone,
            firstName,
            lastName,
            address,
            siteId,
            organizationId: siteId ? undefined : orgId,
            dealerId: orgId ? undefined : dealerId,
            userLevel: selectedUserLevel,
        }).then((r) => {
            if (r.result === AjaxResult.Success) {
                alert('Employee has been restored');
                loadEmployees();
                closeForm();
            } else if (r.result === AjaxResult.Forbidden) {
                enqueueSnackbar(
                    `ERROR: You do not have permission to restore a user in this context`
                );
            } else {
                enqueueSnackbar('ERROR: Failed to restore employee');
                if (r.messages) {
                    setErrors(r.messages);
                }
            }
            setLoading(false);
        });
    };

    const selectedUserLevelOption = useMemo(
        () => ({ label: selectedUserLevel || '', value: selectedUserLevel || '' }),
        [selectedUserLevel]
    );

    const submitDisabled = loading || !firstName || !lastName;

    return (
        <>
            <Dialog open={reAddDialogOpen} maxWidth="sm">
                <DialogContent>
                    <div>
                        A user with the email address: {email} already exists, but has previously
                        been deleted. Would you like to restore?
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={submitRestore}
                    >
                        Submit
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => closeForm()}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={createDialogOpen} fullWidth={true} maxWidth="sm" ref={dialogContentRef}>
                <DialogContent>
                    <form>
                        <FormSection headerText="Contact Info">
                            <FormField label="First Name" required={true}>
                                <FormControl>
                                    <TextField
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setFirstName(e.currentTarget.value)
                                        }
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="Last Name" required={true}>
                                <FormControl>
                                    <TextField
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setLastName(e.currentTarget.value)
                                        }
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="Phone">
                                <FormControl>
                                    <TextField
                                        value={phone}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setPhone(e.currentTarget.value)
                                        }
                                        InputProps={{
                                            inputComponent: PhoneNumberInput,
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="Email" required={true}>
                                <FormControl>
                                    <TextField
                                        type="text"
                                        value={email}
                                        placeholder="Email"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setEmail(e.currentTarget.value)
                                        }
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="Confirm Email" required={true}>
                                <FormControl>
                                    <TextField
                                        type="text"
                                        value={confirmEmail}
                                        placeholder="Confirm Email"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setConfirmEmail(e.currentTarget.value)
                                        }
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="Address">
                                <FormControl>
                                    <TextField
                                        type="text"
                                        value={address}
                                        placeholder="Address"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setAddress(e.currentTarget.value)
                                        }
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="User Type">
                                <FormControl className={classes.formControlForUserType}>
                                    <DialogSelect
                                        options={userLevels}
                                        value={selectedUserLevelOption}
                                        onChange={(
                                            value: ValueType<{ value: string; label: string }>
                                        ) => {
                                            const val = value as { value: string; label: string };
                                            setSelectedUserLevel(
                                                val && (val.value as UserLevel | null)
                                            );
                                        }}
                                    />
                                </FormControl>
                            </FormField>
                        </FormSection>
                        <ValidationErrors errors={errors} />
                    </form>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={submitDisabled}
                            onClick={submit}
                        >
                            Submit
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => closeForm()}>
                            Cancel
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};

const useStyles = makeStyles({
    input: {
        padding: '5px',
    },
    formControlForUserType: {
        minWidth: '300px',
    },
});
