import React, { useCallback, useEffect, useState } from "react";
import { TextField, InputAdornment, CircularProgress, useTheme } from "@material-ui/core";
import SearchOutlined from "@material-ui/icons/SearchOutlined";

import { debounce } from "./helpers";

interface Props {
    onChange: (value: string) => void;
    showLoading: boolean;
    onRef: (el: HTMLElement | null) => void;
    onFocus: () => void;
    onBlur: () => void;
}

export function SearchInput({ onChange, onRef, showLoading, onFocus, onBlur }: Props) {
    const theme = useTheme();
    const [value, setValue] = useState("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleValueChangeDebounced = useCallback(debounce((_value: string) => {
        onChange(_value);
    }, 600), []);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }, []);

    useEffect(() => {
        handleValueChangeDebounced(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <TextField
            ref={onRef}
            variant="outlined"
            placeholder="Search for Site"
            inputProps={{
                style: {
                    padding: "10px",
                    minWidth: "400px",
                }
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        {showLoading
                            ? <CircularProgress size={24} color="inherit" />
                            : <SearchOutlined />
                        }
                    </InputAdornment>,
                style: {
                    backgroundColor: theme.backgroundThemeColor1,
                }
            }}
            onChange={handleChange}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    )
}

