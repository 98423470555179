import { Icon, Menu, MenuItem, makeStyles } from "@material-ui/core";
import React, { useState, useRef } from "react";

import { useAuth } from "../../features/auth/context";
import { ViewEmployeeDialog } from "../pages/events/ViewEmployee/ViewEmployeeDialog";

export function UserMenu() {
    const classes = useStyles();
    const { username, logout, changePassword } = useAuth();

    const [menuIsVisible, setMenuIsVisible] = useState(false);
    const [userInfoDialogIsVisible, setUserInfoDialogIsVisible] = useState(false);

    const anchorEl = useRef(null);

    return (
        <div>
            <div className={classes.menuContainer}>
                <span onClick={() => setMenuIsVisible(true)}>{username}</span>
                <Icon className={classes.icon} onClick={() => setMenuIsVisible(true)}>
                    account_circle
                </Icon>
                <Icon className={classes.icon} onClick={() => setMenuIsVisible(true)} ref={anchorEl}>
                    keyboard_arrow_down
                </Icon>
            </div>
            <Menu
                open={menuIsVisible}
                onClose={() => setMenuIsVisible(false)}
                anchorEl={anchorEl.current}
            >
                <MenuItem onClick={() => setUserInfoDialogIsVisible(true)}>
                    User Profile
                </MenuItem>

                <MenuItem onClick={changePassword}>
                    Change Password
                </MenuItem>

                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
            <ViewEmployeeDialog
                open={userInfoDialogIsVisible}
                forCurrentUser={true}
                onClose={() => setUserInfoDialogIsVisible(false)}
            />
        </div>
    );
}

const useStyles = makeStyles(() => ({
    menuContainer: {
        display: "flex",
        justifyContent: "center",
        gap: "4px",
    },
    icon: {
        cursor: "pointer",
        verticalAlign: "middle"
    }
}));
