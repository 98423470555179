import { useSnackbar } from "notistack";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import useRouter from "use-react-router";

import { AuthApi } from "../../../rest/authApi";
import {
    BASE_SERVER_URL,
    ISSUE_COLLECTOR_SCRIPT,
    ISSUE_COLLECTOR_ELEMENT_IDS,
    REFRESH_TOKEN_THROTTLE_SECONDS,
} from "../../../constants";
import { AjaxResult } from "../../../enums/ajaxResult";
import { UserLevel } from "../../../enums/userLevel";
import { useTabVisibility } from "../../../hooks/useTabVisibility";

import { UserDataFromToken } from "../types";
import { getUserDataFromToken } from "../utils";
import { EMPTY_USER_DATA } from "../consts";

export interface IMainAuthContext {
    authApi: AuthApi;
    username: string | null;
    email: string | null;
    roles: string[];
    dealerId: number | null;
    orgId: number | null;
    siteId: number | null;
    eulaAccepted: boolean | null;
    isDealerActive: boolean | null;
    isOrgActive: boolean | null;
    isSiteActive: boolean | null;
    isGlobalAdmin: boolean;
    isDealerAdmin: boolean;
    isOrgAdmin: boolean;
    isSiteAdmin: boolean;
    isSiteEmployee: boolean;
    loginInProgress: boolean;
    loginErrors: string[];
    mainLogout: () => void;
    mainLogin: () => void;
    isAuthenticated: boolean;
    refreshToken: (force?: boolean, expire?: Date) => void;
}

const MainAuthContext = createContext<IMainAuthContext | null>(null);

export function MainAuthContextProvider({ children }: React.PropsWithChildren<unknown>) {
    const { enqueueSnackbar } = useSnackbar();
    const { isTabVisible } = useTabVisibility();
    const authApi = useMemo(() => new AuthApi(BASE_SERVER_URL, enqueueSnackbar), [enqueueSnackbar]);
    const [cookies, setCookie, removeCookie] = useCookies(['refreshToken']);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { history } = useRouter();
    const token = localStorage.getItem('token');
    const [loggedInUser, setLoggedInUser] = useState<UserDataFromToken>(
        token !== null
            ? getUserDataFromToken(token)
            : EMPTY_USER_DATA
    );
    const [loginInProgress, setLoginInProgress] = useState(false);

    const mainLogout = useCallback(() => {
        localStorage.removeItem('sessionId');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshTokenExpDate');
        removeCookie('refreshToken');

        setLoggedInUser(EMPTY_USER_DATA);
        setIsAuthenticated(false);
        history.push('/');
        // eslint-disable-next-line
    }, []);

    const [loginErrors, setLoginErrors] = useState<string[]>([]);
    const mainLogin = useCallback(() => {
        setLoginInProgress(true);
        setLoginErrors([]);
        let succeeded = false;
        authApi.getToken().then((r) => {
            if (r.result === AjaxResult.Success && r.data) {
                var { sessionId, accessToken, refreshToken, refreshTokenExpiration } = r.data;

                localStorage.setItem('sessionId', sessionId);
                localStorage.setItem('token', accessToken);
                localStorage.setItem('refreshTokenExpDate', refreshTokenExpiration);
                setCookie('refreshToken', refreshToken, {
                    path: '/',
                });

                const newUserData = getUserDataFromToken(accessToken);
                setLoggedInUser(newUserData);
                succeeded = true;
                setLoginErrors([]);
            } else if (r.result === AjaxResult.Failure && r.messages) {
                setLoginErrors(r.messages);
            } else {
                setLoginErrors(['Login failed']);
            }
            setIsAuthenticated(succeeded);
            setLoginInProgress(false);
        });
    }, [authApi, setCookie]);

    useEffect(() => {
        if (isAuthenticated) {
            const script = document.createElement('script');
            script.src = ISSUE_COLLECTOR_SCRIPT;
            script.async = true;
            document.body.appendChild(script);
            document.body.removeChild(script);
        } else {
            ISSUE_COLLECTOR_ELEMENT_IDS
                .map(it => document.getElementById(it))
                .forEach(it => it ? it.remove() : undefined);
        }
    }, [isAuthenticated])

    const refreshToken = (force = false, expire?: Date) => {
        console.debug('refreshToken: force=' + force);
        const expDate = localStorage.getItem('refreshTokenExpDate');
        if (
            !force &&
            expDate &&
            new Date(expDate).getTime() - new Date().getTime() >
            REFRESH_TOKEN_THROTTLE_SECONDS * 1000
        ) {
            return;
        }

        const accessToken = localStorage.getItem('token');
        const refreshToken = cookies.refreshToken;
        if (accessToken && refreshToken) {
            authApi.refreshToken(accessToken, refreshToken).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    var { accessToken, refreshToken, refreshTokenExpiration } = r.data;

                    if (expire != null) {
                        refreshTokenExpiration = expire.toISOString();
                    }

                    localStorage.setItem('token', accessToken);
                    localStorage.setItem('refreshTokenExpDate', refreshTokenExpiration);
                    setCookie('refreshToken', refreshToken, {
                        path: '/',
                    });
                    return;
                }
                mainLogout();
            });
        } else {
            mainLogout();
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const expDate = localStorage.getItem('refreshTokenExpDate');

        if (
            token != null &&
            expDate != null &&
            new Date(expDate) >= new Date()
        ) {
            const newUserData = getUserDataFromToken(token);
            setLoggedInUser(newUserData);
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [isTabVisible]);

    const isGlobalAdmin = loggedInUser.roles.indexOf(UserLevel.GlobalAdmin) !== -1;
    const isDealerAdmin = loggedInUser.roles.indexOf(UserLevel.DealerAdmin) !== -1;
    const isOrgAdmin = loggedInUser.roles.indexOf(UserLevel.OrgAdmin) !== -1;
    const isSiteAdmin = loggedInUser.roles.indexOf(UserLevel.SiteAdmin) !== -1;
    const isSiteEmployee = loggedInUser.roles.indexOf(UserLevel.SiteEmployee) !== -1;

    return (
        <MainAuthContext.Provider
            value={{
                ...loggedInUser,
                isGlobalAdmin,
                isDealerAdmin,
                isOrgAdmin,
                isSiteAdmin,
                isSiteEmployee,
                loginErrors,
                mainLogout,
                mainLogin,
                isAuthenticated,
                refreshToken,
                loginInProgress,
                authApi,
            }}
        >
            {children}
        </MainAuthContext.Provider>
    );
}

export function useMainAuth() {
    const value = useContext(MainAuthContext);

    if (!value) {
        throw new Error("AuthContext consumed outside of AuthContextProvider scope")
    }

    return value;
}
