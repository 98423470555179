import React, { useMemo } from 'react';
import { NavBar } from './navBar';
import { PageBody } from './shared/pageBody';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useSnackbar } from 'notistack';
import { ConfirmDialog } from './confirmDialog';
import { ConfirmDialogContext } from './confirmContext';
import { useConfirmDialogGlobal } from '../hooks/useConfirmDialog';
import { ApiContext, buildApiContextValue } from './apiContext';
import { OrgSiteService } from './orgSiteContext';
import { SnackBarService } from './snackbarService';
import { EulaPopup } from './shared/eulaPopup';
import { IdlePopup } from './shared/idlePopup';
import SignalRProvider from '../signalr/provider';

import { AuthDialog } from "../features/auth/components";
import { AuthContextProvider, useAuth } from "../features/auth/context";

export const App = ({ children }: { children?: any }) => {
    const {
        confirmMessage,
        resolveWithFalse,
        resolveWithTrue,
        showConfirmDialog,
        confirmDialogIsVisible,
        setConfirmDialogIsVisible,
    } = useConfirmDialogGlobal();

    return (
        <SnackBarService>
            <AuthContextProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ApiService>
                        <ConfirmDialogContext.Provider value={{ showConfirmDialog }}>
                            <OrgSiteService>
                                <AppContent>
                                    {children}
                                    <ConfirmDialog
                                        isVisible={confirmDialogIsVisible}
                                        message={confirmMessage}
                                        close={() => setConfirmDialogIsVisible(false)}
                                        confirm={resolveWithTrue}
                                        cancel={resolveWithFalse}
                                    />
                                </AppContent>
                            </OrgSiteService>
                        </ConfirmDialogContext.Provider>
                    </ApiService>
                </MuiPickersUtilsProvider>
            </AuthContextProvider>
        </SnackBarService>
    );
};

const AppContent = ({ children }: { children?: any }) => {
    const { username, eulaAccepted, isAuthenticated } = useAuth();

    const userAuthenticated = username && isAuthenticated;
    return userAuthenticated
        ? (
            <SignalRProvider>
                {<IdlePopup />}
                {!eulaAccepted && <EulaPopup />}
                <NavBar />
                <PageBody>{children}</PageBody>
            </SignalRProvider>
        )
        : <AuthDialog />;
};

const ApiService = ({ children }: { children?: any }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { logout } = useAuth();
    const api = useMemo(
        () => buildApiContextValue(logout, enqueueSnackbar),
        [logout, enqueueSnackbar]
    );
    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
